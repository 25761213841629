    /* eslint-disable import/no-extraneous-dependencies */
// Dependencies

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import
{
  faHandshake, faUniversalAccess, faUserFriends, faUserShield, faBook, faClock,
} from '@fortawesome/free-solid-svg-icons';
import CardInformation from './CardInformation/CardInformation';
// Resources
import './Nosotros.css';

export default function Nosotros() {
  const cardsSolutions = [
    {
      image: <FontAwesomeIcon size="2x" icon={faHandshake} />,
      subtitle: 'Respaldo',
      text: 'Llevamos muchos años en el mercado peruano trabajando de la mano con la SUNAT para elevar la eficiencia y productividad de las empresas peruanas.',
    },
    {
      image: <FontAwesomeIcon size="2x" icon={faUniversalAccess} />,
      subtitle: 'Adaptabilidad',
      text: 'Nuestros sistemas son adaptables aumentando la eficiencia de la gestión diaria y simplificando el control de tu empresa.',
    },
    {
      image: <FontAwesomeIcon size="2x" icon={faUserFriends} />,
      subtitle: 'Soporte 24/7',
      text: 'Resolvemos tus dudas a cualquier hora, cualquier día de la semana para que cuentes con respuestas inmediatas y tu operación esté asegurada.',
    },
    {
      image: <FontAwesomeIcon size="2x" icon={faUserShield} />,
      subtitle: 'Seguridad',
      text: 'Confidencialidad, integridad y alta disponibilidad. Definición de perfiles de usuarios.',
    },
    {
      image: <FontAwesomeIcon size="2x" icon={faBook} />,
      subtitle: 'Control',
      text: 'Optimiza procesos de auditorias. Revisa los procesos diarios de tu empresa a través de nuestros de reportes dinámicos.',
    },
    {
      image: <FontAwesomeIcon size="2x" icon={faClock} />,
      subtitle: 'Tiempo',
      text: 'Lo hacemos simple porque conocemos tu negocio. Nuestros sistemas optimizan el tiempo de registro de todos tus procesos.',
    },
  ];

  return (
    <section id="Beneficios" className="nosotros">
      <div className="nostros_title">
        <h1 className="title title--large__mobile">¿POR QUÉ NOSOTROS?</h1>
        <p className="nosotros__p">Porque somos la empresa líder de facturación electrónica en Perú. Brindamos el servicio de facturación electrónica a todos los sectores económicos del país, contamos con alianzas y una variedad amplia de sistemas que cubren perfectamente las necesidades del empresario peruano.</p>
      </div>
      <div className="nosotros-list-solutions">
        {cardsSolutions.map((card) => (
          <CardInformation
            key={cardsSolutions.indexOf(card)}
            image={card.image}
            subtitle={card.subtitle}
            text={card.text}
          />
        ))}
      </div>
    </section>
  );
}
