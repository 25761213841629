import React, {useState} from 'react';
import './LibroReclamaciones.css'
import imagen from './libro_reclamaciones.png';
import logo from './LogoPaleSinfondo.png';
import api from '../../../../api';

function DetalleReclamo(){
  const [page, setPage]= useState(0);
  
  return(
  <div className='FormNuevoReclamaciones'>
    <div className='LogoLibroReclamaciones'>
      <div>
      <label className='tituloReclamaciones'>Libro de <br/>Reclamaciones</label>
      </div>
      <div>
        <img src={imagen} alt='libro reclamaciones' height={74} width={200}/>
      </div>
    </div>

    <div className='PruebaDivR'>
      <div className='RuCReclamaciones'>
        <img src={logo} alt='libro reclamaciones' height={74} width={200}/>
        <label>Pale Consultores E.I.R.L</label>
      </div>

      <div>
        <label>Producto/Servicio <br />
          <select 
            name="resProduServi"
            value={'seleccione Doc'}
          >  
              <option name="resProduServi" value="Servicio">Producto</option>
              <option name="resProduServi" value="Producto">Servicio</option>
          </select>
        </label>
      </div>

      <div className='ReclamoQueja'>
        <div>
            <input type="radio" name="repreEmpresa" value="SI" /><label> Reclamo</label>
        </div>
        <div>
            <input type="radio" name="repreEmpresa"  value="NO" /><label>Queja</label>
        </div>
      </div>

      <div>
        <label>
            Detalle reclamo: <br/>
            <textarea  className= '' value={"doc"} required/>
        </label>
      </div>

      <div>
        <label>
            Pedido: <br />
            <textarea  className= '' value={"doc"} required/>
        </label>
      </div>

      <div>
        <label>
            Adjuntar documentos: <br/>
            <input  type='file'/>
        </label>
      </div>
    </div> 
  </div>
  );
}

export default DetalleReclamo;





/*
class RegistroForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          xxx: 'Ingrese RUC',
          ruc: '',
          rucRpta:'',
          razonSocial:'',
          direccion:'',
          fono:'' 
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        this.setState({xxx: event.target.value});
      }
    
      handleSubmit(event) {
        alert('An essay was submitted: ' + this.state.xxx);
        this.state.ruc = this.state.xxx;
        event.preventDefault();
        console.log(this.state.xxx);
      }
    
      render() {
        return (
        <div className='FormNuevoReclamaciones'>
            <div className='LogoLibroReclamaciones'>
                <div >
                    <h1 className='tituloReclamaciones'>Libro de <br/>Reclamaciones</h1>
                </div>
                <div>
                    <img src={imagen} alt='libro reclamaciones' height={74} width={200}/>
                </div>
            </div>

            <div className='PruebaDivR'>
                <form onSubmit={this.handleSubmit}>  
                    <h1>Ingrese RUC de la empresa a la que desea hacer llegar su reclamo: </h1>

                    <div className='RuCReclamaciones'>
                        <input value={this.state.xxx} onChange={this.handleChange}  required/>
                    </div>

                    <div className='DatosRuc'>                                                                                                                                                                                                                                              
                        <label>RUC: {this.state.ruc}</label>
                        <label>RUC: {this.state.rucRpta}</label>
                        <label>Razón Social: {this.state.razonSocial}</label>
                        <label>Dirección: {this.state.direccion}</label>
                        <label>Telefono: {this.state.fono}</label> 
                    </div>

                    <div className='ContinuarReclamaciones'>
                        <input type="submit" value="Submit" />
                    </div>
                </form>
            </div>

        </div>

        );
      }
}

export default RegistroForm;*/
/*321213
    
    constructor(props){
        super(props)
        this.state={isVerified:false};
        this.state={
          ruc:"123456789",
          mostrar:false,
          rucRpta:"20491228299",
          razonSocial:"Pale Consultores E.I.R.L",
          direccion:"Pasaje America p218",
          fono:"989766789",
          consulta:{
            empresa:[],
            mensaje:undefined,
          }};
        this.handleChange=this.handleChange.bind(this);
        this.consultarRUC=this.handleSubmit.bind(this);
      }
      
      handleChange(event) {
        this.setState({value: event.target.ruc});
      }

      handleSubmit(event) {
        alert('A name was submitted: ' + this.state.ruc);
        event.preventDefault();
      }

/*
    async consultarRUC(e) {
        console.log('Prueba de funcionalidad');
        /*e.preventDefault();
        try{
            console.log('Prueba de funcionalidad');
          /*const consultar = await api.ConsultaRuc.consultarRUC(this.state.ruc);
          // console.log(consultar.mensaje);
          this.setState({mostrar:true,
            consulta:{empresa:[].concat(this.state.consulta,consultar.empresa)},
            mensaje:{mensaje:[].concat(this.state.consulta,consultar.mensaje)},
           
          });
          this.state.consulta.mensaje=consultar.mensaje;
          console.log(this.state.consulta.empresa);
        }catch(error){
          console.error('Error',error)
//          this.setState({mostrar:false});
    
        }
      }
///////////////

render(){
    return(
    // <div className="FormNuevoReclamaciones">
    //     <div className="">
    //         <h1>Libro de Reclamaciones</h1>
    //         <img src={imagen} alt='libro reclamaciones'/>
    //     </div>
    //     <div className="">
    //         <h1>Libro de Reclamaciones</h1>
    //         <img src={imagen} alt='libro reclamaciones'/>
    //     </div>

    // </div>
        <div className='FormNuevoReclamaciones'>
            <div className='LogoLibroReclamaciones'>
                <div >321
                    <h1 className='tituloReclamaciones'>Libro de <br/>Reclamaciones</h1>
                </div>
                <div>
                    <img src={imagen} alt='libro reclamaciones' height={74} width={200}/>
                </div>
            </div>
            <div className='PruebaDivR'>
                <form onSubmit={this.handleSubmit}>    
                    <h1>Ingrese RUC de la empresa a la que desea hacer llegar su reclamo: </h1>

                    <div className='RuCReclamaciones'>
                        <input maxLength={11} type="text" name="ruc" id="ruc" placeholder="Ingrese RUC" onChange={this.handleChange} value={this.state.ruc} required/>
                    </div>

                    <div className='DatosRuc'>
                        <label>RUC: {this.state.rucRpta}</label>
                        <label>Razón Social: {this.state.razonSocial}</label>
                        <label>Dirección: {this.state.direccion}</label>
                        <label>Telefono: {this.state.fono}</label>
                    </div>

                    <div className='ContinuarReclamaciones'>
                        <input type="submit" value="Submit"/>
                    </div>
                </form>
            </div>
        </div>
        )
    }
*/