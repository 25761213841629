import React, { useState, useEffect } from "react";
import '../css/eventos.css';
// import imagen from './libro_reclamaciones.png';
import swal from 'sweetalert';
import Modal from "react-modal"
import { event } from "react-ga";
//import logoPalePrincipal from '../../../../images/Logo_720.png';
import logoPale from "../../../../images/Logo_720.png";
import logoPaleWhite from "../../../../images/Logo_monocromático blanco720.png";


const BuscarEvento = () => {
    const [prueba, setPrueba] = useState('');
    const [empresa, setEmpresa] = useState(null);
    const [id, setId] = useState('');
    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [fecha, setFecha] = useState('');
    const [visible, setVisible] = useState('no');

    const [notiRUC, setNotiRUC] = useState('')

    const [evento, setEvento] = useState();
    const [tamano, setTamano] = useState([]);

    const manejadorInputCambio = ({ target }) => {
        setPrueba(target.value);

        let IsValidRUC = /^(0|[2][0-9]{10})$/

        if (target.value === '') {
            setNotiRUC('');
            setVisible('no')
            setNombre('')
            setFecha('')
            setDireccion('')
        }
        // else if (!IsValidRUC.test(target.value)) {
        //     setNotiRUC('Ingrese un RUC válido')
        //     setVisible('no')
        // }

        else if (target.value.length === 36) {
            setNotiRUC('')
            setVisible('si')

            let jsonData2 = {

                Token: "484848",
                Operacion: "1",
                Conexion:
                {
                    BaseDeDatos: "pale",
                    Procedimiento: "USP_EVE_EVENTOS_TXPK"
                },
                Parametros:
                    [
                        "BC8F213F-C148-4F46-A829-4DC68F478FC5"
                    ]

            };

            console.log({ jsonData2 });

            try {
                fetch(`https://apis.paleconsultores.com/v2/Pale/`, {
                    method: "POST",
                    body: JSON.stringify(jsonData2),
                    headers: { "Content-type": "application/json; charset=UTF-8" }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data)
                        setId(data.Resultado[0][0].Cod_Evento)
                        setNombre(data.Resultado[0][0].Des_Evento)
                        setFecha(data.Resultado[0][0].Fecha_Inicio)
                        setDireccion(data.Resultado[0][0].LugarEvento)
                    });

            } catch (error) {
                alert('Error con el servicio, por favor actualice la página');
            }



        }
        else {
            console.log('Holiiiiii')
        }
    }

    const listarEventos = () => {
        let jsonData2 = {

            Token: "484848",
            Operacion: "1",
            Conexion:
            {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_EVENTOS_TT"
            },
            Parametros:
                [
                ]
        };

        console.log({ jsonData2 });

        try {
            fetch(`https://apis.paleconsultores.com/v2/Pale/`, {
                method: "POST",
                body: JSON.stringify(jsonData2),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setTamano(data.Resultado[0])
                    console.log("===========================")
                    console.log(data.Resultado[0])
                    // setTamano(data.Resultado[0].length)

                    console.log("===========================")
                });

        } catch (error) {
            alert('Error con el servicio, por favor actualice la página');
        }
    }

    useEffect(() => {
        listarEventos()

    }, [])
    //============================= Modal =============================
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [codigoRecibe, setCodigoRecibe] = useState('');

    function openModal() {
        setIsOpen(true);
        let x = 0

        // if (isValidCel === true) {
        //   enviarCodigo(celular, codigo)
        // } else if (isValidEmail === true) {
        //   enviarCodigoMail(correo, codigo)
        // }

        //enviarCodigo(celular,codigo)
        //enviarCodigoMail(correo,codigo)
    }
    function afterOpenModal() { subtitle.style.color = '#f00'; }
    function closeModal() {
        // setIsOpen(false);
        // setCodigo('')
        // setCodigoRecibe('')
    }

    const [isValidCode, setIsValidCode] = useState(false)

    //=================================================================

    return (
        <div>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    // style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div>
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Ingrese el código de validación</h2>
                        <button onClick={closeModal}>close</button>
                    </div>
                    <div className="DatosModal">
                        <input
                            placeholder="0000"
                            maxLength={4}
                            value={codigoRecibe}
                        // onChange={manejadorInputCodigo}
                        />

                        <a href="">No recibí el código de validación</a>

                        <button className="botonModal"
                        // onClick={validarCodigo}
                        >Validar código</button>
                    </div>
                </Modal>
            </div>

            <nav className="header__navbar">
                <nav className="navContainer">
                    <a href="/" className="header__brand">
                        <img className="header__brand-image" src={logoPaleWhite} alt="logoPale" />
                    </a>
                    <h1>EVENTOS</h1>
                </nav>

                <a href="">Acceder</a>
            </nav>



            <div className='FormBusquedaEvento'>

                <div className='DivEvento'>
                    {/* <label className='titleRuc'>Ingrese nombre del evento: </label> */}

                    <div className='BuscadorEvento'>
                        <input
                            className='inputPrincipal'
                            placeholder={"Buscar evento..."}
                            maxLength={36}
                            onChange={manejadorInputCambio}
                            value={prueba}
                            required />
                    </div>
                    {visible === "si" ?
                        <div className='EventosContainer'>
                            <div className='DatosEvento'>
                                <div className='DatosRecuperadosTitle'>
                                    <label>Evento :</label><br />
                                    <label>Fecha :</label><br />
                                    <label>Lugar :</label><br />
                                </div>

                                <div className='DatosRecuperados'>
                                    <label>{nombre}</label><br />
                                    <label>{fecha}</label><br />
                                    <label>{direccion}</label><br />
                                </div>
                            </div>

                            <div className='BtnContainer'>
                                <a href={"/Evento/" + id}>
                                    <button
                                        className='ButtonBusqueda'
                                    // disabled ={page == FormTitles.length - 1}
                                    >
                                        Realizar reclamo
                                    </button>
                                </a>
                            </div>
                        </div> : visible === "no" ?
                            <div className='EventosContainer2'>
                            </div>
                            : null}

                </div>

                <div className='Body'>
                    <label className='titleRuc'>Lista de eventos: </label>

                    <div className='EventosLista'>
                        {tamano.map((x) => (
                            <a href={"/Evento/" + x.Cod_Evento}>
                                <button
                                    className="evento"
                                    key={x.Cod_Evento}
                                >
                                    Evento: {x.Des_Evento}, Lugar: {x.LugarEvento}
                                </button>
                            </a>
                        ))}
                    </div>

                </div>

            </div>
        </div>
    )
}

export default BuscarEvento;