/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

// Resources
import CardSolutions from './CardSolutions/CardSolutions';

import './Solutions.css';

export default function Solutions() {
  const _cardsSolutions = [
    {
      type: 1,
      plan: 'Básico',
      precio: '49',
      item1: '100 comprobantes por mes',
      item2: 'Emisión fácil, rápida e intuitiva',
      item3: 'Certificado Digital Incluido',
      item4: 'Multi-dispositivos',
      item5: 'Soporte vía correo electrónico',
      item6: 'Incluye IGV',
      item7: '* 7 días de prueba gratis',
    },
    {
      type: 1,
      plan: 'Avanzado',
      precio: '69',
      item1: '500 comprobantes por mes',
      item2: 'Emisión fácil, rápida e intuitiva',
      item3: 'Certificado Digital Incluido',
      item4: 'Multi-dispositivos',
      item5: 'Soporte por teléfono ( lunes a viernes 9:00 am – 6:00 pm)',
      item6: 'Incluye IGV',
      item7: '* 7 días de prueba gratis',
    },
    {
      type: 1,
      plan: 'Completo',
      precio: '99',
      item1: '1000 comprobantes por mes',
      item2: 'Emisión fácil, rápida e intuitiva',
      item3: 'Certificado Digital Incluido',
      item4: 'Multi-dispositivos',
      item5: 'Soporte por teléfono 24/7',
      item6: 'Incluye IGV',
      item7: '* 7 días de prueba gratis',
    },
  ];

  return (
    <section id="Precios" className="solutions">
      <div className="solutions_title">
        <h1 className="title title--large__mobile">Nuestros Planes</h1>
        <p className="solutions__p">Tenemos los mejores precios para que empieces ahora.</p>

      </div>
      <div className="solutions__info">
            {_cardsSolutions.map((solution) => (
              <CardSolutions
                key={_cardsSolutions.indexOf(solution)}
                type={solution.type}
                plan={solution.plan}
                precio={solution.precio}
                item1={solution.item1}
                item2={solution.item2}
                item3={solution.item3}
                item4={solution.item4}
                item5={solution.item5}
                item6={solution.item6}
                item7={solution.item7}
              />
            ))}
        </div>
    </section>
  );
}