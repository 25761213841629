// Dependencias
import React, { useState } from 'react';
// import './FormEncuesta.css';
import DEFAULT_IMAGE from '../../Home/2-Body/3-Solutions/CardSolutions/images/consulta-400.jpg';
import FormPreguntas from '../Preguntas/FormPreguntas';
import RespuestaNo from '../RespuestaNo/RespuestaNo';
import DEFAULT_IMAGE_LOGO from '../../../../images/Logo_720.png'
import '../FormEncuesta/FormEncuesta.css'
export default function FormEncuestaDatos(props)
{
    const [pregunta1, setpregunta1] = useState('');

  if(!props.consultado){
      return(
        null
          );
  }
  else{
    if(Object.keys(props.RIncidencia).length===null){
        return(
            <div>

            </div>
        )
        }
      else{
        const Fecha= new Date(props.RIncidencia.resultado["1"].Fecha_Inicia).toLocaleDateString();
        return (
          <div>
                <div className="FormEncuesta">
                  <div>
                    {/* <img  className="FormEncuesta__image" src={DEFAULT_IMAGE_LOGO} alt='logo de Ifacturación'/> */}
                    <h1>Encuesta de Satisfacción </h1>
                    <hr/>
                    <p>Gracias a tu opinión podremos seguir mejorando la experiencia de atención de 
                      nuestro canal de Web. Ayúdanos respondiendo la siguiente encuesta:
                    </p>
                  </div>
              
                <div>
                    {/* <div>
                    <img className="FormEncuesta__bloque1-image" src={DEFAULT_IMAGE} alt='usuario quien atendio la incidencia' />
                    </div> */}
                    <p>Hola,soy  {' '}
                     <span className="span"> {props.RIncidencia.resultado["1"].Cod_UsuarioIncidencia}</span> , por favor evalúa mi atención del día: <span className="span">{Fecha} </span>  {' '} del tema:  {' '}<span className="span">{props.RIncidencia.resultado["1"].Detalle}</span> 
                    </p>
                </div>
                <div className="FormEncuesta__bloque2">
                    <p className="FormEncuesta__p">Tu opinión es muy importante para nosotros</p>
                    <div>
                    <div>
                        <p>1. ¿Tu problema fue resuelto?</p>
                    </div>
                    <div id="pregunta" className="FormEncuesta__bloque2-columnas" value={pregunta1} onChange={(e)=> setpregunta1(e.target.value)}>
                       <div>
                          <label>
                            <input type="radio" name="pregunta"  value="SI" />
                                SI
                            </label>
                       </div>
                       <div>
                            <label>
                              <input type="radio" name="pregunta"  value="NO" />
                                  NO
                            </label>
                       </div>
                      
                    </div>
                    </div>
                </div>
                <div>
                    {pregunta1==="SI"? <FormPreguntas RRespuesta={props.RIncidencia} />: pregunta1==="NO"?<RespuestaNo RRespuesta={props.RIncidencia} />:null}
                </div>
                </div>
          </div>
        
        );

      }
    
    }
 
  }




























