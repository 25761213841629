/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import ComboBox from '../../components/App/DownloadVoucher/ComboBox/ComboBox';

// Resources
import './FormField.css';

export default function FormField({
  formType = 'input',
  type = 'text',
  name = 'input',
  // eslint-disable-next-line react/prop-types
  styletype = 1,
  content = name,
  value = '',
  handleChange,
}) {
  // eslint-disable-next-line consistent-return
  function selector() {
    switch (formType) {
      case 'input':
        return <input className="form-control" type={type} name={name} placeholder={`Ingrese su ${content}`} onChange={handleChange} value={value} />;
      case 'textarea':
        return <textarea className="form-control" rows={5} name={name} placeholder={`Ingrese su ${content}`} onChange={handleChange} value={value} />;
      case 'button':
        return <button className="button button--darkblue" type="submit">{content}</button>;
      case 'facturacion':
        return <ComboBox />;
      default:
    }
  }
  if (styletype !== 1) {
    return (
      <div className="form-field__2">
        {selector()}
      </div>
    );
  }
  return (
    <div className="form-field__1">
      {selector()}
    </div>
  );
}


FormField.propTypes = {
  formType: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};
