// Depedencias
import React from 'react';
import './RespuestaForm.css';
import Solutions from '../../Home/2-Body/3-Solutions/Solutions';
export default function RespuestaForm(props)
{
  if(!props.consultado){
      return(
        null
          );
  }
  else{
    if(Object.keys(props.REmpresa).length===1){

      if(props.REmpresa.empresa["1"]===undefined){
          return (
            <div>
                <div className="RespuestaForm">
              <h1>
                  EMPRESA  NO OBLIGADA 
              </h1>
              </div>
              <Solutions/>
            </div>
            
              )
      }
      else{
        return (
          <div>
                <div className="RespuestaForm">
            <h1>
              EMPRESA OBLIGADA DESDE :
              {' '}
              {props.REmpresa.empresa["1"].usuario_fecha_obliga}
              {' '}
            </h1>
            <div>
              <label>
                ACTIVIDAD ECONOMICA:
              </label>
              <div>
                {/* <input type="text" value={props.REmpresa.empresa["1"].usuario_actividad_economica} /> */}
                <p>{props.REmpresa.empresa["1"].usuario_actividad_economica}</p>
              </div>
            </div>
            <div>
              <label>
               FECHA DE OBLIGATORIEDAD:
              </label>
              <div>
                <input type="text" value={props.REmpresa.empresa["1"].usuario_fecha_obliga} />
              </div>
            </div>
            <div>
              <label>
              TIPO:
            </label>
              <div>
                <input type="text" value={props.REmpresa.empresa["1"].usuario_tipo} />
              </div>
            </div>
            <div>
              <h2>
              ESCOJA SU PAQUETE
              </h2>
          
            </div>
          </div>
             <Solutions/>
          </div>
        
        );

      }
    
    }
  
  }

}

