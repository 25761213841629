import React, {useState,useEffect} from 'react';
import './LibroReclamaciones.css'
import imagen from './libro_reclamaciones.png';
import api from '../../../../api';
import EmpresaBuscar from './Empresa';
import swal from 'sweetalert';

function BusquedaEmpresa(){
  const [prueba, setPrueba] = useState('');
  const [empresa,setEmpresa] = useState(null);
  const [ruc ,setRuc] = useState('');
  const [nombre ,setNombre] = useState('');
  const [direccion ,setDireccion] = useState('');
  const [visible,setVisible] = useState('no');

  const manejadorVisible = (e) => {
    setVisible(e.target.value)
  }
   
  const manejadorInputCambio = ({target}) => {
    setPrueba(target.value);

    if (target.value.length === 11) {
      try {
        fetch(`https://apis.paleconsultores.com/v2/ConsultaRuc/?RUC=${target.value}&TOKEN=484848`)
        .then((res)=>res.json())
        .then((data) => {
            console.log(data)

            if (data.Exito === "0") {
              
              swal('El Ruc es inválido por favor ingrese un ruc válido');
              setVisible('no')
              setPrueba('')

            } else if (data.Exito === "1") {
              
                if (data.Resultado[0][0].Respuesta == "EXISTE") {
                  setEmpresa(data);
                  setRuc(data.Resultado[0][0].Nro_Documento);
                  setNombre(data.Resultado[0][0].Cliente);
                  setDireccion(data.Resultado[0][0].Direccion);
    
                  setVisible('si')
                } else {
                  
                  swal('El Ruc NO EXISTE por favor ingrese un ruc EXISTENTE');
                  setVisible('no')
                  setPrueba('')

                }

            } else {
              swal('Error con el servicio, por favor actualice la página');
              setVisible('no')
            }

            
          });
        
      } catch (error) {
        alert('Error con el servicio, por favor actualice la página');
      }
        

    } else {
      console.log(target.value)
    }
  }

  const manejadorBuscador = (e) =>{
    e.preventDefault()
    console.log(prueba)

    setVisible('si')

    fetch(`https://apis.paleconsultores.com/v2/ConsultaRuc/?RUC=${prueba}&TOKEN=484848`)
    .then((res)=>res.json())
    .then((data) => {
        setEmpresa(data);
        setRuc(data.Resultado[0][0].Nro_Documento);
        setNombre(data.Resultado[0][0].Cliente);
        setDireccion(data.Resultado[0][0].Direccion);
      });
  }

  return(

    <div className='FormBusquedaEmpresa'>
       <div className='BusquedaLibroReclamaciones'>
          <div className='TitReclam'>
            <label className='tituloReclamaciones'>Libro de <br/>Reclamaciones</label>
          </div>
          <div>
            <img src={imagen} alt='libro reclamaciones' height={74} width={200}/>
          </div>
      </div>

      <div className='PruebaDivBusqueda'>
        <label className='titleRuc'>Ingrese RUC de la empresa a la que desea hacer llegar su reclamo: </label>

        <div className='RuCReclamaciones'>
          <input  
            className='inputPrincipal' 
            placeholder={"RUC"} 
            maxLength={11}
            onChange={manejadorInputCambio}
            value={prueba}
            required/>
          
          {/* <button 
            className=''
             onClick={manejadorBuscador}
          >buscar</button> */}

        </div>

        {visible === "si" ?
        <div className='DatosRucContainer'>
          <div className='DatosRuc'>                                                                                                                                                                                                                                              
            <div className='DatosRecuperadosTitle'>
              <label>RUC :</label><br/>
              <label>Razón Social :</label><br/>
              <label>Dirección :</label><br/>
            </div>

            <div className='DatosRecuperados'>
              <label>{ruc}</label><br/>
              <label>{nombre}</label><br/>
              <label>{direccion}</label><br/>
            </div> 
          </div>

          <div className='BtnBusqueda'>
            <a href={"/LibroReclamaciones/"+ruc}>
              <button 
                className='ButtonBusqueda'
                // disabled ={page == FormTitles.length - 1}
              >
                Realizar reclamo
              </button>
            </a>
          </div> 
        </div> : visible === "no"? 
          <div className='DatosRucContainer2'>
          </div>
        :null}   

      </div> 
      
    </div>
  );
}

export default BusquedaEmpresa;