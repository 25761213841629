/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resour
import CardSolution from './Card_Solutions/Card_Solutions';


export default function Body() {
  return (
    <div className="container">
      <CardSolution />
    </div>
  );
}
