import React from 'react';
import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import './Script.css';
export default function Script(){
    return(
        <div>
            <Header />
                    <div className="script">
                        <div className="script-card">
                        <h1>CONSULTAS DE COMPROBANTES</h1>
                        <ul>
                            <li>
                                <div>
                                    <span>VERIFICA COMPROBANTES NO ENVIADOS DENTRO DE LOS PLAZOS 7 DIAS</span>
                                    <p>SET DATEFORMAT DMY
                                        SELECT ccp.id_ComprobantePago,ccp.Cod_TipoComprobante,ccp.Serie,ccp.Numero,ccp.Doc_Cliente,
                                        ccp.Nom_Cliente,ccp.Total,ccp.FechaEmision 
                                        FROM dbo.CAJ_COMPROBANTE_PAGO ccp
                                        WHERE ccp.Cod_EstadoComprobante NOT IN ('ACS','ENB','ENF','ENE','FIN','REC','RFIN','REREC','BAJA')
                                        AND ccp.Cod_TipoComprobante IN ('FE','NCE') AND ccp.Serie LIKE 'F%' AND ccp.Cod_Libro=14
                                        AND DATEDIFF (DAY,convert(DATETIME,CONVERT(VARCHAR(10),ccp.FechaEmision,103)),
                                        convert(DATETIME,CONVERT(VARCHAR(10),GETDATE(),103))){'>'}7
                                        AND CAST(FechaEmision AS DATE){'>'}='01/01/2020'</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA COMPROBANTES NO ENVIADOS</span>
                                    <p> SELECT * FROM CAJ_COMPROBANTE_PAGO WHERE Cod_EstadoComprobante='FIN' AND (Valor_Firma ='' OR Valor_Resumen='') 
                                        AND Cod_TipoComprobante='FE' AND Cod_Libro=14
                                        AND CAST(FechaEmision AS DATE){'>'}='01/01/2020'
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA QUE NO EXISTAN CAMPOS NULL EN COD IGV O TIPO</span>
                                    <p>SELECT distinct ccp.id_ComprobantePago,ccd.id_Detalle,ccp.Cod_TipoComprobante,ccp.Serie,
                                        ccp.Numero,ccd.Cod_TipoIGV,ccd.Tipo,ccp.FechaEmision 
                                        FROM dbo.CAJ_COMPROBANTE_PAGO ccp INNER JOIN dbo.CAJ_COMPROBANTE_D ccd ON ccp.id_ComprobantePago = ccd.id_ComprobantePago
                                        WHERE (ccd.Cod_TipoIGV IS NULL OR ccd.Cod_TipoIGV='' OR ccd.Tipo IS NULL OR ccd.Tipo='') AND ccp.Cod_Libro=14  
                                        AND ccp.Cod_TipoComprobante IN ('BE','FE','NCE','NDE') AND Flag_Anulado=0
                                        AND CAST(FechaEmision AS DATE){'>'}='01/01/2020' and ccp.Cod_estadocomprobante='EMI'
                                        ORDER BY ccp.Cod_TipoComprobante,ccp.Serie,ccp.Numero,ccd.id_Detalle,FechaEmision</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA LOS TIPOS DE DOCUMENTO DE BOLETAS Y FACTURAS</span>
                                    <p> SELECT id_ComprobantePago,Cod_TipoComprobante,Serie,Numero,Cod_TipoDoc,Doc_Cliente,Nom_Cliente,Direccion_Cliente,
                                        FechaEmision FROM CAJ_COMPROBANTE_PAGO WHERE Cod_TipoComprobante='FE' AND (Cod_TipoDoc{'<>'}6 OR LEN(Doc_Cliente){'<>'}11 
                                        OR LEN(REPLACE(Nom_Cliente,' ',''))=0)AND Cod_Libro=14 AND Flag_Anulado=0 AND CAST(FechaEmision AS DATE){'>'}='01/01/2020'</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA EXISTENCIA DE MONTOS NEGATIVOS EN FACTURAS Y BOLETAS</span>
                                    <p>SELECT * FROM CAJ_COMPROBANTE_PAGO ccp inner join CAJ_COMPROBANTE_D ccd on ccp.id_ComprobantePago=ccd.id_ComprobantePago
                                    WHERE ccp.Cod_TipoComprobante in ('FE','BE') AND Cod_Libro=14 
                                    AND (ccd.Sub_Total{'<'}0 OR IGV{'<'}0) AND CAST(FechaEmision AS DATE){'>'}='01/01/2020'
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA BOLETAS EMITIDAS {'>'}700 SIN DNI</span>
                                    <p>SET DATEFORMAT DMY
                                        SELECT * FROM CAJ_COMPROBANTE_PAGO
                                        WHERE  ((Nom_Cliente LIKE '%CLIENTE%' OR Nom_Cliente LIKE '%VARIO%') 
                                        AND (Cod_Libro = 14) AND ( Serie  LIKE 'B%' OR Serie LIKE 'F%') AND (Total {'>'}=700)AND 
                                        CAST(FechaEmision AS DATE){'>'}='01/01/2020') and Cod_EstadoComprobante ='EMI'
                                        OR ((Doc_Cliente='00000001' OR Doc_Cliente='00000000')
                                        AND (Cod_Libro = 14) AND ( Serie  LIKE 'B%' OR Serie LIKE 'F%') AND (Total {'>'}=700) AND 
                                        CAST(FechaEmision AS DATE){'>'}='01/01/2020') and Cod_EstadoComprobante ='EMI'
                                        ORDER BY FechaEmision DESC </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA NCE QUE NO TIENEN RELACION EN LOS MONTOS</span>
                                    <p>SELECT F.FechaEmision,  F.Cod_TipoComprobante, F.Serie, F.Numero, NC.Glosa, F.Total, R.Cod_TipoRelacion, R.Valor, 
                                        R.Obs_Relacion, NC.Cod_TipoComprobante AS Expr1, NC.Serie AS Expr2, NC.Numero AS Expr3, NC.Glosa AS Expr4, 
                                        NC.Total AS Expr5
                                        FROM CAJ_COMPROBANTE_PAGO AS F INNER JOIN
                                            CAJ_COMPROBANTE_RELACION AS R ON F.id_ComprobantePago = R.Id_ComprobanteRelacion INNER JOIN
                                            CAJ_COMPROBANTE_PAGO AS NC ON R.id_ComprobantePago = NC.id_ComprobantePago
                                        WHERE (R.Cod_TipoRelacion = 'CRE') AND F.Total + NC.Total != 0 AND CAST( F.FechaEmision AS DATE){'>'}='01/01/2020' 
                                        and nc.cod_estadocomprobante='emi'
                                        ORDER BY FechaEmision DESC  </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA CAMPO NULOS O VACIOS DE BOLETAS Y FACTURAS</span>
                                    <p>SELECT * FROM CAJ_COMPROBANTE_PAGO WHERE (Nom_Cliente = '' OR Nom_Cliente = ' ' OR (LEN(Nom_Cliente){'<'}3))AND
                                     Cod_Libro = 14 AND (SERIE LIKE 'b%' OR SERIE LIKE 'f%')
                                       AND CAST(FechaEmision AS DATE){'>'}='01/01/2020'
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA SI HAY CLIENTES CON CARACTERES ESPECIALES</span>
                                    <p>SELECT * FROM pri_cliente_proveedor WHERE CLIENTE like '.%' </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA MONTOS MAYORES DE 10000 </span>
                                    <p>SELECT * FROM CAJ_COMPROBANTE_PAGO
                                       WHERE  ( (Cod_Libro = 14) AND ( Serie  LIKE 'B%' OR Serie LIKE 'F%') AND (Total {'>'}=10000)
                                       and Cod_EstadoComprobante='EMI')
                                       order by FechaEmision desc
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span> VERIFICA PRODUCTOS CON PRECIO UNITARIO ALTO</span>
                                    <p> select p.id_ComprobantePago,p.FechaEmision, p.Serie,p.Numero, d.Cantidad,d.Descripcion,d.PrecioUnitario, d.Sub_Total,
                                     p.Cod_EstadoComprobante from CAJ_COMPROBANTE_D d inner join CAJ_COMPROBANTE_PAGO p 
                                    on (p.id_ComprobantePago =d.id_ComprobantePago) where d.PrecioUnitario {'>'} 20 AND D.Cod_UnidadMedida='GLL' 
                                    and Cod_EstadoComprobante='EMI' AND ( Serie  LIKE 'B%' OR Serie LIKE 'F%')
                                    order by FechaEmision desc 
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA DOCUMENTOS QUE TIENEN LETRAS</span>
                                    <p>SELECT * FROM CAJ_COMPROBANTE_PAGO
                                        WHERE Cod_Libro=14 AND Cod_TipoComprobante IN ('nce','be') AND Serie LIKE 'b%' AND ISNUMERIC(Doc_Cliente)=0 
                                        and cod_estadocomprobante='emi'
                                        order by FechaEmision desc</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICA DOCUMENTOS QUE TIENEN LETRAS</span>
                                    <p>SELECT * FROM CAJ_COMPROBANTE_PAGO
                                    WHERE CAST(FechaEmision AS DATE)='20190708' AND Cod_Libro=14 AND Cod_TipoComprobante IN ('nce','be') AND Serie LIKE 'b%' AND 
                                    ISNUMERIC(Doc_Cliente)=0</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>VERIFICAR QUE LOS DNI TENGAN MAS DE 7 DIG</span>
                                    <p>SET DATEFORMAT DMY
                                        SELECT id_ComprobantePago, Cod_TipoComprobante, Serie, Numero, Cod_TipoDoc, Id_Cliente, Doc_Cliente,FechaEmision, 
                                        Cod_Moneda, Impuesto, Total FROM CAJ_COMPROBANTE_PAGO 
                                        WHERE Cod_TipoComprobante in ('BE','nce' ) and Serie like 'b'+'%' AND Cod_EstadoComprobante='EMI' 
                                        AND Cod_Libro='14'and cast(FechaEmision as date) {'>'}='01/01/2020'and Cod_TipoDoc=1
                                        GROUP BY id_ComprobantePago, Cod_TipoComprobante, Serie, Numero, Cod_TipoDoc,Id_Cliente, Doc_Cliente,FechaEmision,
                                        Cod_Moneda, Impuesto, Total
                                        HAVING LEN(Doc_Cliente)!=8   order by FechaEmision desc
                                        SELECT id_ComprobantePago,Cod_Libro,Cod_Caja,Cod_Turno,Cod_TipoOperacion,Cod_TipoComprobante,Serie,
                                        Numero,Id_Cliente,Cod_TipoDoc,Doc_Cliente,Nom_Cliente,FechaEmision,Cod_EstadoComprobante 
                                    FROM CAJ_COMPROBANTE_PAGO
                                    WHERE Cod_TipoComprobante in ('FE','nce' ) and Serie like 'f'+'%' AND LEN(Doc_Cliente)!=11 AND Cod_EstadoComprobante 
                                     in ('EMI','INI') AND Cod_Libro='14'

                                     --select * from PRI_CLIENTE_PROVEEDOR where Cliente like ' %'

                                    select * from CAJ_COMPROBANTE_PAGO where Nom_Cliente like ' %' AND ( Serie  LIKE 'B%' OR Serie LIKE 'F%')  and cod_estadocomprobante='emi'
                                        </p>
                                </div>
                            </li>
                         </ul>
                    </div>
                </div>
            <Footer />
        </div>
    )

}