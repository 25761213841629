/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import './CardSolutions.css';

export default function CardSolutions(
  {
    type = 1,
    plan = 'lorem',
    precio = 'lorem a',
    item1 = 'ipsum',
    item2 = 'ipsum',
    item3 = 'ipsum',
    item4 = 'ipsum',
    item5 = 'ipsum',
    item6 = 'ipsum',
    item7 = 'ipsum',
  },
) {
  if (type === 1) {
    return (
      <div className="card-solutions">
        <div>
          <p className="card-solutions__sectionPlan">{plan}</p>
        </div>
        <div className="card-solutions__seccionPrecio">
          <p>S/ {precio}.00</p>
          <hr className="card-solutions__line" />
        </div>
        <div className="card-solutions__seccionInformacion">
          <ul>
            <li>{item1}</li>
            <li>{item2}</li>
            <li>{item3}</li>
            <li>{item4}</li>
            <li>{item5}</li>
            <li>{item6}</li>
            <li className="card-oferta">{item7}</li>
          </ul>
        </div>
        <div className="card-solutions__seccionFooter">
          {/* <a href={`/empresaNueva/${precio}`}><button className="sizeButton">Empezar Ahora</button></a> */}
          <a href="/contacto"><button className="sizeButton">Empezar Ahora</button></a>

        </div>
      </div>
    );
  }
}

CardSolutions.propTypes = {
  type: PropTypes.number,
  serviceClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  descripcion: PropTypes.string,
  image: PropTypes.string,
  imagerespURL: PropTypes.string,
};
