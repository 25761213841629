/* eslint-disable react/button-has-type */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState, useEffect } from 'react';

// Resources


import './Hero.css';

export default function Hero() {
  // Integer state to make Hexagons rotate
  const [count, setCount] = useState(0);

  // Effect to increase count
  useEffect(() => {
    setTimeout(() => {
      setCount(count + 0.150);
    }, 10);
  });

  return (
    <section id="Hero" className="hero">
      <div className="intro__text">
        <div className="intro-text__container">
          <div>
            <h1 className="intro-text__paragraph"> Adquiere tu sistema de Facturación </h1>
          </div>
          <div>
            <h1 className="intro-text__paragraph">Desde S/49.00 </h1>
          </div>
          <a href="/registro"><button> MÁS INFORMACIÓN </button></a>
        </div>
      </div>
    </section>
  );
}
