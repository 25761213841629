/* eslint-disable indent */
//Dependencies
import React from 'react';




export default class PagEncuesta extends React.Component{
  constructor(props){
    super(props)
   
    this.state={isVerified:false};
    
    this.state={
    
     id:this.props.match.params.idticket
    
    };
   
  }
   
  componentDidMount() {
    this.fetchData();
  }

  async fetchData(){
 
    window.location.href =`https://www.pse.pe/anulacion/${this.state.id}`; 
    return null;
  };

render(){
        return(
            <section>
                
             </section>
            )
        }

}