// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import BlogCard from './BlogCard/BlogCard';
import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import img1 from './BlogCard/images/facturacion.png';
import img2 from './BlogCard/images/electronica.jpg';
import img3 from './BlogCard/images/medio-ambiente.jpg';
import img4 from './BlogCard/images/img2.jpg';

export default function Blog() {
  const blogCard = [
    {
      id: 1,
      title: 'Beneficios de implementar un sistema de punto de venta',
      date: '30/04/2019',
      image: img1,
      description: 'Un sistema de punto de venta es un software que gestiona el proceso de venta mediante una interfaz accesible para los vendedores o compradores. Un único sistema informático permite la creación e impresión del ticket o factura electrónica —con los detalles de las referencias y precios— de los artículos vendidos, actualiza los cambios en el..',
    },
    {
      id: 2,
      title: 'Empresas ahorran 67% de sus costos con la emisión de comprobantes electrónicos',
      date: '22/04/2019',
      image: img2,
      description: 'La facturación electrónica, refiere la Sunat, mejora su productividad, al optimizar no solo sus procesos y sistemas de emisión, sino también de cobranza. El uso de las facturas electrónicas representa un ahorro de hasta 67% en los costos de impresión, gastos de distribución y archivo en comparación con los comprobantes físicos , aseguró la Sunat..',
    },
    {
      id: 3,
      title: 'Factura electrónica contribuye con el medio ambiente',
      date: '09/04/2019',
      image: img3,
      description: 'Una de las principales ventajas de la facturación electrónica es el ahorro en costos que otorga al no ser necesario comprar papel, imprimir y entregar facturas físicas. Pero ¿has pensado en el beneficio que esto representa para el planeta? Anteriormente las empresas parecían no tener la ecología en su lista de prioridades..',
    },
    {
      id: 4,
      title: 'Factura electrónica en el Peru',
      date: '15/11/2019',
      image: img4,
      description: 'Mediante la facturación electrónica tendrás la oportunidad de estar en una red de negocios, en donde todas las empresas podrán intercambiar documentos comerciales de manera electrónica. Ello les permitirá lograr mayor eficiencia en sus procesos logísticos y contables, ahorrar costos de almacenamiento, minimizar el riesgo de facturas erradas y ayudar en la protección del medio ambiente..',
    },
  ];
  return (
    <section>
      <Header />
      {blogCard.map((blog) => (
        <BlogCard
          id={blog.id}
          key={blogCard.indexOf(blog)}
          title={blog.title}
          date={blog.date}
          image={blog.image}
          description={blog.description}
        />
      ))}
      <Footer />
    </section>
  );
}
