/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Resources
import './SolutionsCard.css';
// eslint-disable-next-line import/no-duplicates
import DEFAULT_IMAGE from '../imagen/farmacia-200x200.png';
// eslint-disable-next-line import/no-duplicates

export default function SolutionsCard(
  {
    type = 1,
    image = DEFAULT_IMAGE,
    alt = 'image alternate text',
    title = 'lorem',
    items = [],
  },
) {
  if (type === 1) {
    return (
      <div className="solutionsCard">
        <div>
          <picture className="solutionsCard__image">
            <img className="solutioncard__image" src={image} alt={alt}/>
          </picture>
        </div>
        <div>
          <p className="solutionsCard_seccionTitle">{title}</p>
        </div>
        <div className="solutionsCard__seccionInformacion">
          <ul>
            {items.map((item) => <li>{item.value}</li>)}
          </ul>
        </div>
      </div>
    );
  }
}
SolutionsCard.propTypes = {
  type: PropTypes.number,
  serviceClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  descripcion: PropTypes.string,
};
