import React from 'react';
import PropTypes from 'prop-types';
import './LegislationCard.css';

export default function LegislationCard(
  {
    tittle = '',
    body = '',
    date = '',
  },
) {
  return (
    <section className="LegislationCard">
      <h3 className="LegislationCard__tittle">
        {tittle}
      </h3>
      <p className="LegislationCard__date">
        {date}
      </p>
      <div className="LegislationCard__line">
        <div className="LegislationCard__point" />
        <div className="LegislationCard__lin" />
      </div>
      <p className="LegislationCard__body"> 
        {body}
      </p>
    </section>
  );
}

LegislationCard.propTypes = {
  body: PropTypes.string,
  date: PropTypes.string,
  tittle: PropTypes.string,
};