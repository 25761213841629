/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resources
import './styles.css';
import { faFacebookSquare, faWhatsappSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faHome, faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoPale from '../../../../images/Logo_negativo720.png';

export default function Footer() {
  return (
    <footer>
      <div className="footer_info">
        <div>
          <img className="logo__Footer" src={logoPale} alt="logo_footer" />
          <hr />
          <p>Desarrollamos soluciones a tu medida</p>
        </div>
        <div>
          <h2 className="footer_info_subtitulos">Síguenos en:</h2>
          <div>
            <a target="_blank" className="social" href="https://www.youtube.com/PaleConsultores">
              <FontAwesomeIcon icon={faYoutubeSquare} size="3x" color="white"/>
            </a>
            <a target="_blank" className="social" href="https://api.whatsapp.com/send?phone=+51984125256&amp;text=Quiero%20saber%20mas%20de%20iFacturacion ">
              <FontAwesomeIcon icon={faWhatsappSquare} size="3x" color="white"/>
            </a>
            <a target="_blank" className="social" href="https://www.facebook.com/ifacturacioncusco/">
              <FontAwesomeIcon icon={faFacebookSquare} size="3x" color="white"/>
            </a>
          </div>
        </div>
        <div>
          <h2 className="footer_info_subtitulos">Caracteristícas</h2>
          <div className="footer__servicios">
            <div className="footer__servicios_Margin">
              <p>Bajo Costo</p>
              <p>Multiplataforma</p>
              <p>Sin Limites</p>
            </div>
            <div>
              <p>Soporte 16h/6</p>
              <p>Fácil de Usar</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr className="footer_info_hr"/>
      </div>
      <div className="footer_info">
        <div >
        <a target="_blank" className="social" href="https://api.whatsapp.com/send?phone=+51984125256&amp;text=Quiero%20saber%20mas%20de%20iFacturacion ">
            <FontAwesomeIcon icon={faPhone} />
            {' '}
              984-125-256
          </a>
          <p>9:00 AM - 6:00 PM</p>
        </div>
        <div>
          <a href="mailto:info@palerp.com">
            <FontAwesomeIcon icon={faMailBulk} />
            {' '}
              Info@palerp.com
          </a>
          <p>Consultas On-line</p>
        </div>
        <div>
          <a>
            <FontAwesomeIcon icon={faHome}/>
            {' '}
              Urb.Marcavalle E-20,Wanchaq
          </a>
          <p>Cusco-Perú</p>
        </div>
      </div>
      <div className="copyrigth">
        <p>Copyright © 2019 Pale Consultores</p>
      </div>
    </footer>
  );
}
