// const BASE_URL = 'http://18.217.65.58:4000';
const BASE_URL = 'https://apis.consultasruc.com:4000';
// const BASE_URL = 'http://localhost:3000';
// const BASE_URL = 'https://apigeneral.consultasruc.com:4000';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const randomNumber = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1)) + min;

const simulateNetworkLatency = (min = 30, max = 1500) => delay(randomNumber(min, max));

async function callApi(endpoint, options = {}) {
  await simulateNetworkLatency();

  // eslint-disable-next-line no-param-reassign
  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

const apiGeneral = {
  
  IncidenciaCliente:{
    traerIncidencia(id){
        return callApi(`/api/AIncidentes/procedimientos/USP_SOP_INCIDENCIAS_TXPK/Id_Incidencia/${id}`, { 
            headers: {
              'Content-Type': 'application/json',
            },
          });
    },
    guardarIncidencia(datos){
        return callApi('/api/AIncidentes/save', {
            method: 'POST',
            body: JSON.stringify(datos),
          });
     },
  },
};

export default apiGeneral;
