import React, { useState ,Fragment} from 'react';
import { CulqiProvider, Culqi } from "react-culqi";

import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import apiCulqi from '../../../apiCulqi';
import './pagosCulqi.css';

export default class PagEncuesta extends React.Component{
    constructor(props){
      super(props)
     
      this.state={isVerified:false};
      
      this.state={
 
        totalEncriptado:this.props.match.params.totalPago,
        total:0,
        respuetaCulqi:undefined,
      };
     
    }

  render(){
    const desTotal=atob(this.state.totalEncriptado);
    this.state.total=parseInt(desTotal)*100;
    console.log(this.state.total);

    return(

      <section>
             <Header />
                
                 <div className="PagosCulqi">
                        <CulqiProvider
                        publicKey="pk_test_f64100b736294f4a"
                        amount={this.state.total}
                        title="Servicio de Pagos"
                        description=""
                        onToken={token => {
                            // console.log("token received", token);
                            this.state.respuetaCulqi=token;
                            const datos={
                                amount: this.state.total,
                                currency_code: "PEN",
                                email: this.state.respuetaCulqi.email,
                                source_id: this.state.respuetaCulqi.id
                            }
                            console.log(datos);
                          
                                const crear= apiCulqi.cargos.crearCargo(datos);
                                console.log(crear);
                                
                              
                        }}
                        onError={error => {
                            console.error("something bad happened", error);
                        }}
                        // Uncomment `options` to see customizations take place
                        options={{
                            style: {
                            maincolor: "#119EEF",
                            buttontext: "black",
                            maintext: "darkorange",
                            desctext: "pink",
                            logo: "https://www.ifacturacion.pe/image/Icono_azul.ico"
                            }
                        }}
                        >
                        <div>
                            <Culqi>
                            {({ openCulqi, setAmount, amount }) => {
                                return (
                                <div>
                                    {/* <h3>Total a pagar: {amount}</h3> */}
                                    <button onClick={openCulqi}>Realizar Pago</button>
                                
                                </div>
                                );
                            }}
                            </Culqi>
                        </div>
                        </CulqiProvider>
                 </div>
            <Footer />
          </section>
    )
  }
  
  }



