import React, { useState, useEffect } from "react";
import '../css/eventos.css';
// import imagen from './libro_reclamaciones.png';
import swal from 'sweetalert';
import Modal from "react-modal"
import { event } from "react-ga";
//import logoPalePrincipal from '../../../../images/Logo_720.png';
import logo1 from "../images/bodal1.png";
import logo2 from "../images/bodal2.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { IoPersonSharp, IoPhonePortrait, IoMailSharp, IoTicketSharp, IoTrashSharp, IoPencilSharp, IoAddSharp } from "react-icons/io5";




const BuscarEvento = () => {
    const [prueba, setPrueba] = useState('');
    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [fecha, setFecha] = useState('');


    //=====================================================================================
    const [buscar, setBuscar] = useState("");
    const [codigoUsuarioSeleccionado, setCodigoUsuarioSeleccionado] =
        useState("");

    const [dataTable, setDataTable] = useState([]);

    const [nombreAsistente, setNombreAsistente] = useState("");
    const [celularAsistente, setCelularAsistente] = useState("");
    const [email, setEmail] = useState("");
    const [pax, setPax] = useState("");
    const [observacion, setOservacion] = useState("");

    const [openModalAgregar, setOpenModalAgregar] = useState(false);
    const [openModalEditar, setOpenModalEditar] = useState(false);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);

    const [evento, setEvento] = useState("BC8F213F-C148-4F46-A829-4DC68F478FC5");
    //=====================================================================================

    const { codigo } = useParams();

    const TraerEvento = () => {
        let jsonData2 = {

            Token: "484848",
            Operacion: "1",
            Conexion:
            {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_EVENTOS_TXPK"
            },
            Parametros:
                [
                    codigo
                ]

        };

        console.log({ jsonData2 });

        try {
            fetch(`https://apis.paleconsultores.com/v2/Pale/`, {
                method: "POST",
                body: JSON.stringify(jsonData2),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setNombre(data.Resultado[0][0].Des_Evento)
                    setFecha(data.Resultado[0][0].Fecha_Inicio)
                    setDireccion(data.Resultado[0][0].LugarEvento)
                });

        } catch (error) {
            alert('Error con el servicio, por favor actualice la página');
        }
    }

    useEffect(() => {
        TraerEvento()

    }, [])


    //============================= Modal =============================
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [codigoRecibe, setCodigoRecibe] = useState('');

    function openModal() {
        setIsOpen(true);
        let x = 0

        // if (isValidCel === true) {
        //   enviarCodigo(celular, codigo)
        // } else if (isValidEmail === true) {
        //   enviarCodigoMail(correo, codigo)
        // }

        //enviarCodigo(celular,codigo)
        //enviarCodigoMail(correo,codigo)
    }
    function afterOpenModal() { subtitle.style.color = '#f00'; }
    function closeModal() {
        // setIsOpen(false);
        // setCodigo('')
        // setCodigoRecibe('')
    }

    const [isValidCode, setIsValidCode] = useState(false)

    //=================================================================



    const buscarData = (forzarBuscarTodo = false) => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_TXEvento",
            },
            Parametros: [evento, forzarBuscarTodo ? "" : buscar],
        };
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.Resultado.length !== 0) {
                        let dataUsuario = data.Resultado[0];
                        setDataTable(dataUsuario);
                    } else {
                        setDataTable([]);
                    }
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    };

    const editarDatos = (item) => {
        setNombreAsistente(item.Nom_Invitado);
        setCelularAsistente(item.Celular);
        setEmail(item.Email);
        setPax(item.Pax);
        setOservacion(item.Obs_Invitado);
        setOpenModalEditar(true);
    };

    function obtenerFechaHoraActual() {
        const ahora = new Date();

        const anio = ahora.getFullYear();
        const mes = (ahora.getMonth() + 1).toString().padStart(2, "0");
        const día = ahora.getDate().toString().padStart(2, "0");
        const horas = ahora.getHours().toString().padStart(2, "0");
        const minutos = ahora.getMinutes().toString().padStart(2, "0");
        const segundos = ahora.getSeconds().toString().padStart(2, "0");

        return `${día}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
    }

    const eliminar = () => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_E",
            },
            Parametros: [
                codigoUsuarioSeleccionado
            ],
        };
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    setOpenModalEliminar(false)
                    buscarData(true)
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    }

    const registrarData = () => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_G",
            },
            Parametros: [
                codigoUsuarioSeleccionado,
                evento,
                nombreAsistente,
                pax ? pax.toString() : "1",
                celularAsistente,
                email,
                "1",
                obtenerFechaHoraActual(),
                observacion,
                "REYBER",
            ],
        };
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    setOpenModalAgregar(false);
                    setOpenModalEditar(false);
                    buscarData(true)
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
        //-- No borrar
        limpiarData();
    };

    const limpiarData = () => {
        setNombreAsistente("");
        setCelularAsistente("");
        setEmail("");
        setPax("1");
        setOservacion("");
        setCodigoUsuarioSeleccionado("")
    };

    useEffect(() => {
        buscarData();
    }, []);












    function fechaPrueba(fecha) {
        const dateString = fecha;
        const indexOfT = dateString.indexOf('T');
        const timeWithoutDate = dateString.substring(11, 16);
        const dateWithoutTime = dateString.substring(0, indexOfT);
        return dateWithoutTime + ' ' + timeWithoutDate
    }

    return (
        <div>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    // style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div>
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Ingrese el código de validación</h2>
                        <button onClick={closeModal}>close</button>
                    </div>
                    <div className="DatosModal">
                        <input
                            placeholder="0000"
                            maxLength={4}
                            value={codigoRecibe}
                        // onChange={manejadorInputCodigo}
                        />

                        <a href="">No recibí el código de validación</a>

                        <button className="botonModal"
                        // onClick={validarCodigo}
                        >Validar código</button>
                    </div>
                </Modal>
            </div>

            <nav className="header__navbar_boda">
                <nav className="navContainer2">
                    <div className="cont2logo">
                        <img className=".header__navbar_boda-image" src={logo1} alt="logoPale" />
                        <h1> {nombre}</h1>
                    </div>
                    <div>
                        <img className=".header__navbar_boda-image" src={logo2} alt="logoPale" />
                    </div>
                </nav>
            </nav>

            <div className='FormBusquedaEvento'>

                <div className='DivEvento'>
                    <div className='BuscadorEvento'>
                        <input
                            className='inputPrincipal'
                            type="text"
                            value={buscar}
                            onChange={(e) => {
                                setBuscar(e.target.value);
                            }}
                            onBlur={() => {
                                buscarData();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    buscarData();
                                }
                            }}
                            placeholder="Buscar aqui"
                        />
                    </div>
                </div>


                <div className='Body'>
                    <div className="divAgregar">
                        <label className='titleRuc'>Lista de invitados </label>
                        <button
                            className="btnAgregar"
                            onClick={() => {
                                limpiarData();
                                setOpenModalAgregar(true);
                                setCodigoUsuarioSeleccionado("");
                            }}
                        >
                            <IoAddSharp
                                size={30}
                                color="white"
                            /> Agregar
                        </button>
                    </div>
                    <div className='EventosLista'>
                        <div className="table">
                            <div className="table-header">
                                <div className="table-cell-pax">Nro</div>
                                <div className="table-cell">Codigo</div>
                                <div className="table-cell">Nombre</div>
                                <div className="table-cell">Celular</div>
                                <div className="table-cell">Correo</div>
                                <div className="table-cell-pax">Pax</div>
                                <div className="table-cell">Observacion</div>
                                <div className="table-cell">Acciones</div>
                            </div>
                            {dataTable.map((item, index) => (
                                <div className="table-row" key={index}>
                                    <div className="table-cell-pax">{index + 1}</div>
                                    <div className="table-cell">{item.Cod_Invitado}</div>
                                    <div className="table-cell">{item.Nom_Invitado}</div>
                                    <div className="table-cell">{item.Celular}</div>
                                    <div className="table-cell">{item.Email}</div>
                                    <div className="table-cell-pax">{item.Pax}</div>
                                    <div className="table-cell">{item.Obs_Invitado}</div>
                                    <div className="table-cell">
                                        <button
                                            className="btnModificarLista"
                                            onClick={() => {
                                                editarDatos(item);
                                                setCodigoUsuarioSeleccionado(item.Cod_Invitado);
                                            }}
                                        >
                                            <IoPencilSharp
                                                size={25}
                                                color="white"
                                            />
                                        </button>
                                        <button
                                            className="btnEliminarLista"
                                            onClick={() => {
                                                setCodigoUsuarioSeleccionado(item.Cod_Invitado);
                                                setNombreAsistente(item.Nom_Invitado);
                                                setOpenModalEliminar(true);
                                            }}
                                        >
                                            <IoTrashSharp
                                                size={25}
                                                color="white"
                                            />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>








                        {/* ======================== MODALS =========================== */}
                        <div>
                            <Modal
                                isOpen={openModalAgregar}
                                onRequestClose={() => setOpenModalAgregar(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "500px",
                                        height: "500px",
                                        margin: "auto",
                                        padding: "2%",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        backgroundColor: "#001b48",
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>AGREGAR INVITADO</h2>
                                </div>

                                <div className="bodyModal">

                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPersonSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>

                                        <input
                                            type="text"
                                            value={nombreAsistente}
                                            onChange={(e) => {
                                                setNombreAsistente(e.target.value);
                                            }}
                                            placeholder="Nombre"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPhonePortrait
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={celularAsistente}
                                            onChange={(e) => {
                                                setCelularAsistente(e.target.value);
                                            }}
                                            placeholder="Celular"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoMailSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="Correo"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoTicketSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="number"
                                            value={pax}
                                            onChange={(e) => {
                                                setPax(e.target.value);
                                            }}
                                            placeholder="Pax"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoTicketSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={observacion}
                                            onChange={(e) => {
                                                setOservacion(e.target.value);
                                            }}
                                            placeholder="Observación"
                                        />
                                    </div>
                                </div>
                                <button
                                    className="btnModal"
                                    onClick={() => {
                                        registrarData();
                                    }}
                                >
                                    Registrar
                                </button>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalEditar}
                                onRequestClose={() => setOpenModalEditar(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "500px",
                                        height: "500px",
                                        margin: "auto",
                                        padding: "2%",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        backgroundColor: "#001b48",
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>EDITAR INVITADO</h2>
                                </div>

                                <div className="bodyModal">

                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPersonSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>

                                        <input
                                            type="text"
                                            value={nombreAsistente}
                                            onChange={(e) => {
                                                setNombreAsistente(e.target.value);
                                            }}
                                            placeholder="Nombre"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPhonePortrait
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={celularAsistente}
                                            onChange={(e) => {
                                                setCelularAsistente(e.target.value);
                                            }}
                                            placeholder="Celular"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoMailSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="Correo"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoTicketSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="number"
                                            value={pax}
                                            onChange={(e) => {
                                                setPax(e.target.value);
                                            }}
                                            placeholder="Pax"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoTicketSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={observacion}
                                            onChange={(e) => {
                                                setOservacion(e.target.value);
                                            }}
                                            placeholder="Observación"
                                        />
                                    </div>
                                </div>
                                <button
                                    className="btnModal"
                                    onClick={() => {
                                        registrarData();
                                    }}
                                >
                                    Registrar
                                </button>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalEliminar}
                                onRequestClose={() => setOpenModalEliminar(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "500px",
                                        height: "300px",
                                        margin: "auto",
                                        padding: "2%",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        backgroundColor: "#001b48",
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>Eliminar Invitado</h2>
                                </div>
                                <div className="avisoModal">
                                    <label>¿Está seguro de eliminar al invitado {nombreAsistente}?</label>
                                </div>
                                <button
                                    className="btnModal"
                                    onClick={() => {
                                        eliminar();
                                    }}
                                >
                                    Aceptar
                                </button>
                            </Modal>
                        </div>
                    </div>



                    {/* ============================================================*/}


                </div>
            </div>
        </div>
    )
}

export default BuscarEvento;