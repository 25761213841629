/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import LegislationCard from './LegislationCard/LegislationCard';
import './Legislation.css';


export default function Legislation() {
  const cardLegislation = [
    {
      tittle: 'R. S. N.º 043-2019/SUNAT',
      body: 'Publicada el 27/02/2019, amplían el plazo para emplear de forma opcional las versiones 2.0 y 2.1 del formato XML bajo el estándar UBL y extienden la suspensión del requisito adicional para solicitar la autorización de impresión, importación o generación mediante sistemas computarizados para emitir documentos en contingencia.',
      date: '2019',
    },
    {
      tittle: 'R. S. N.º 044-2019/SUNAT',
      body: 'Publicada el 27/02/2019, establecen el uso obligatorio del Sistema de Emisión Electrónica Operador de Servicios Electrónicos y del Sistema de Emisión Electrónica SUNAT Operaciones en Línea y modifican la Resolución de Superintendencia Nº 117-2017/SUNAT.',
      date: '2019',
    },
    {
      tittle: 'R. S. N.º 309-2018/SUNAT',
      body: 'Publicada el 30/12/2018, postergan la fecha a partir de la cual debe cumplirse con contar con la certificación ISO/IEC-27001, colocar el código QR y el código de producto Sunat y establecen medios de envío adicionales para informar los comprobantes de pago y documentos emitidos sin utilizar el Sistema de Emisión Electrónica.',
      date: '2018',
    },
    {
      tittle: 'R. S. N.º 303-2018/SUNAT',
      body: 'Publicada el 28/12/2018, modifica la normativa sobre comprobantes de pago electrónicos, para regular la deducción de gastos por los servicios a los que se refiere el inciso d) del artículo 26°-A del reglamento de la ley del Impuesto a la Renta, y el reglamento de comprobantes de pago',
      date: '2018',
    },
    {
      tittle: 'R. S. N.º 312-2018/SUNAT',
      body: 'Publicada el 28/12/2018, modifica la Resolución de Superintendencia N.º 318-2017/SUNAT que designa como emisores electrónicos a emisores de determinados documentos autorizados y otros, el Reglamento de Comprobantes de Pago, las Normas para la aplicación del Sistema de Pago de Obligaciones Tributarias y otras',
      date: '2018',
    },
    {
      tittle: 'R. S. N.º 308-2018/SUNAT',
      body: 'Publicada el 28/12/2018, amplía el plazo para el uso de sistemas informáticos que emiten tickets.',
      date: '2018',
    },
    {
      tittle: 'R. S. N.º 309-2018/SUNAT',
      body: 'Publicada el 28/12/18, que posterga la fecha a partir de la cual debe cumplirse con contar con la certificación ISO/IEC-27001, colocar el código QR y el código de producto Sunat y establece, en algunos supuestos, medios de envío adicionales para informar los comprobantes de pago y documentos emitidos sin utilizar el sistema de emisión electrónica.',
      date: '2018',
    },
    {
      tittle: 'R. S. N.º 295-2018/SUNAT',
      body: 'Publicada el 20/12/2018, excluyen requisitos a los comprobantes de pago y otros documentos emitidos en contingencia por emisores que estuvieron impedidos de solicitar la autorización de impresión y/o importación de documentos por causas no imputables a ellos.',
      date: '2018',
    },
    {
      tittle: 'R. S. N.° 254 -2018/SUNAT',
      body: 'Publicada el 26/10/2018, regulan nuevos supuestos de concurrencia de la Emisión Electrónica y de la Emisión por otros medios.',
      date: '2018',
    },
    {
      tittle: 'R. S. N.° 253 -2018/SUNAT',
      body: 'Publicada el 26/10/2018, otorga facilidades al emisor electrónico para solicitar la autorización de impresión y/o importación de descuentos y otros.',
      date: '2018',
    },
    {
      tittle: 'R.S. Nº 239-2018/SUNAT',
      body: 'Publicada el 10/10/2018, establecen el uso obligatorio del Sistema de Emisión Electrónica Operador de Servicios Electrónicos y del Sistema de Emisión Electrónica Sunat Operaciones en Línea y modifican la Resolución de Superintendencia Nº 117-2017/SUNAT.',
      date: '2018',
    },
    {
      tittle: 'R.S. N.° 181-2018/SUNAT',
      body: 'Publicada el 28/07/18, posterga la entrada del nuevo modelo de contingencia de los comprobantes de pago y modifica otros plazos.',
      date: '2018',
    },
    {
      tittle: 'R.S. N.° 164-2018/SUNAT',
      body: 'Publicada el 27/06/18, modifica la fecha para usar obligatoriamente la versión 2.1 del Formato XML bajo el estándar UBL en el Sistema de Emisión Electrónica Operador de Servicios Electrónicos (SEE- OSE) y en el Sistema de Emisión Electrónica desarrollado desde los sistemas del contribuyente (SEE - Desde los sistemas del contribuyente).',
      date: '2018',
    },
    {
      tittle: 'R.S. N.° 113-2018/SUNAT',
      body: 'Publicada el 30/04/18, modifica la regulación de la emisión de comprobantes de pago, notas de crédito, notas de débito, comprobantes de retención y comprobantes de percepción no electrónicos realizada por el emisor electrónico por determinación de la SUNAT. Anexo A - Anexo B - Anexo C (Modificada por la R.S. N.° 181-2018/SUNAT)',
      date: '2018',
    },
    {
      tittle: 'R.S. N.° 092 -2018/SUNAT',
      body: 'Publicada el 28/03/18, regula la presentación de la Declaración Jurada sobre el alcance del servicio de comprobación informática y elimina un medio de envío del resumen diario de comprobantes de retención y comprobantes de percepción',
      date: '2018',
    },
    {
      tittle: 'R.S. N.° 318 -2017/SUNAT',
      body: 'Publicada el 28/11/2017, designa como emisores electrónicos a emisores de determinados documentos autorizados y otros. Anexos I–II–III -IV - Anexos V - Anexos VI.',
      date: '2017',
    },
    {
      tittle: 'R.S. N° 155-2017/SUNAT',
      body: 'Publicada el 28/06/2017, designa a los emisores electrónicos del sistema de emisión electrónica y amplían plazos para la vigencia de obligaciones vinculadas a dicho sistema o las suspenden. Anexo I - Anexo II - Anexo III - Anexo IV',
      date: '2017',
    },
    {
      tittle: 'R.S. N° 123-2017/SUNAT',
      body: 'Publicada el 15/05/2017, regula los comprobantes de pago que permiten deducir gastos personales en el Impuesto a la Renta por arrendamiento y/o subarrendamiento de inmuebles y por servicios generadores de Renta de Cuarta Categoría. Designan nuevos emisores electrónicos del Sistema de Emisión Electrónica.',
      date: '2017',
    },
    {
      tittle: 'R.S. N° 117-2017/SUNAT',
      body: 'Publicada el 09/05/2017, aprueba el nuevo sistema de emisión electrónica OPERADOR DE SERVICIOS ELECTRÓNICOS (SEE – OSE).',
      date: '2017',
    },
    {
      tittle: 'R.S. N.° 028 -2017/SUNAT',
      body: 'Publicada el 31/01/2017. Designan nuevos emisores del sistema de emisión electrónica de la guía de remisión electrónica para bienes fiscalizados.',
      date: '2017',
    },
    {
      tittle: 'R.S. N.° 328 -2016/SUNAT',
      body: 'Publicada el 27/12/2016. Designan como nuevos emisores electrónicos del sistema de emisión electrónica para emitir facturas, boletas de venta, notas de crédito y notas de débito a los agentes de percepción especificados y modifican las resoluciones de superintendencia para otorgar nuevo plazo a los agentes de retención y percepción que deben emitir estos comprobantes, así como ampliar el plazo a los obligados a emitir comprobantes en Julio 2016 y reducir los requisitos mínimos sobre comprobantes de pago incorporados por la citada resolución de superintendencia.',
      date: '2016',
    },
    {
      tittle: 'Decreto legislativo Nº 1314',
      body: 'Publicada el 31/12/2016. Faculta a la SUNAT a establecer que sean terceros quienes efectúen labores relativas a la emisión electrónica de comprobantes de Pago y otros documentos a partir del 1 de Enero del 2017',
      date: '2016',
    },
    {
      tittle: 'R.S. N° 274-2015 / SUNAT',
      body: 'Publicada el 30/09/2015, regula la emisión electrónica de los comprobantes de retención y de los comprobantes de percepción.',
      date: '2015',
    },
    {
      tittle: 'R.S. N° 198-2015 / SUNAT',
      body: 'Publicada el 24/07/2015, modifica el sistema de emisión electrónica a fin de establecer casos excepcionales en los que los sujetos designados como emisores electrónicos pueden continuar emitiendo y otorgando documentos en formatos impresos y tickets o cintas de máquinas registradoras.',
      date: '2015',
    },
    {
      tittle: 'R.S. N° 185-2015',
      body: 'Publicada el 13/07/2015, modifica la normativa sobre comprobantes de pago para incorporar nuevos requisitos mínimos, realizar mejoras en el sistema de emisión electrónica, implementar la boleta de venta electrónica consolidada y facilitar el traslado de bienes vendidos usando una factura electrónica emitida desde el SEE-Portal.',
      date: '2015',
    },
    {
      tittle: 'R.S. N° 300-2014 / SUNAT',
      body: 'Publicada el 30/09/2014, modifica los Sistemas de Emisión Electrónica de facturas y boletas de venta para facilitar entre otros, la emisión y el traslado de bienes realizado por los emisores electrónicos itinerantes y por quienes emiten o usan boleta de venta electrónica; y designa emisores electrónicos del nuevo sistema.',
      date: '2014',
    },
    {
      tittle: 'R.S. N° 199-2014/SUNAT',
      body: 'Publicada el 28/06/2014, modifica el Sistema de Emisión Electrónica desarrollado desde los sistemas del contribuyente y normas relacionadas para facilitar el traslado de bienes, le emisión de notas electrónicas y otros.',
      date: '2014',
    },
    {
      tittle: 'Junio',
      body: 'Finaliza el piloto del proyecto “Factura electrónica” de SUNAT y está disponible para las empresas desde el 01 de Julio.',
      date: '2013',
    },
    {
      tittle: 'Abril',
      body: 'Se crea el sistema de emisión electrónica de comprobantes de pago, desarrollado desde los sistemas del contribuyente.',
      date: '2012',
    },
    {
      tittle: 'Noviembre',
      body: 'SUNAT convoca a grandes contribuyentes al piloto del proyecto de comprobantes de pago.',
      date: '2010',
    },
  ];
  return (
    <div>
      <h1 className="Legislation__Tittle">Legislacion</h1>
      {
            cardLegislation.map((card) => (
              <LegislationCard
                key={cardLegislation.indexOf(card)}
                tittle={card.tittle}
                body={card.body}
                date={card.date}
              />
            ))
}
      <p>              </p>
    </div>
  );
}
