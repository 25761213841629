import React from 'react';
import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import './pagos.css';

export default class PagPago extends React.Component{

    constructor(props){
        super(props)
        this.state={
         rucEncriptado:this.props.match.params.rucEmisor,
         totalEncriptado:this.props.match.params.totalPago,
         descripcionEncriptado:this.props.match.params.descripcionPago,
         ruc:'',
         total:'',
         descripcion:'',
        };  
      }

      render(){
        const desRuc=atob(this.state.rucEncriptado);
        const desTotal=atob(this.state.totalEncriptado);
        const desDescripcion=atob(this.state.descripcionEncriptado);
        this.state.ruc=desRuc
        this.state.total=desTotal
        this.state.descripcion=desDescripcion
          return(
             <section>
                   <Header />
                   <div className="PagPagos">
                                <h1>Pagos de Ifacturación</h1>
                            <div className="pagos">
                            <form>
                                <div>
                                    <label>RUC Emisor:</label>
                                    <input value={this.state.ruc} onChange={this.handleChange} disabled />
                                  
                                </div>
                               
                               <div>
                                    <label> Monto: </label>
                                    <input value={this.state.total} onChange={this.handleChange} disabled />
                               </div>
                              
                            
                                <div>
                                        <label>  Descripción: </label>
                                        <input value={this.state.descripcion} onChange={this.handleChange} disabled />
                                </div>
                               
                              
                            </form>
                            </div>
                            <div>
                            <form method="POST" action="https://secure.paytoperu.com/eng/customize" id="frmRegister" name="frmRegister" target="_blank">
                                    <p>
                                        Hacer click aqui para Depositar
                                    </p>        
                                    <p>
                                        <input type="hidden" name="ruc" id="ruc" value="20491228297"/>
                                        <input type="hidden" name="moneda" id="moneda" value="2"/>
                                        <input type="hidden" name="importe" id="importe" value={this.state.total}/>
                                        <input type="hidden" name="cantidad" id="cantidad" value="1"/>
                                        <input type="hidden" name="descripcion" id="descripcion" value={this.state.descripcion}/>
                                        <input type="image" width="350" height="100" align="center" src="https://secure.paytoperu.com/comercio/MarcaPayToPeru_A.png" title="Payments with pay to peru" alt="PayToPeru.com - The easy and secure way to make payments"/>
                                    </p>
                            </form>
                            </div>
                        </div>
                        <Footer />
             </section> 
          )
      }
}