/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resour
import FormEmObligada from '../FormEmObligada/FormEmObligada';

export default function Body() {
  return (
    <div className="container">  
      <FormEmObligada />
    </div>
  );
}
