/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React, { useEffect,useState,useRef } from 'react';

// Resources
import Hero from './1-Hero/Hero';
import Solutions from './3-Solutions/Solutions';
import ClientList from './6-ClientList/ClientList';
import Nosotros from './Nosotros/Nosotros';
import DescargaComprobante from './DescargaComprobante/DescargaComprobante';
import StaticHeader from '../1-Header/StaticHeader/StaticHeader'
import "./Body.css"

function getScrollPosition({ element, useWindow }) {

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(window.scrollY)
  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }
  useEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}
export default function Body() {
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
  )
  return (
    <div className="container">
      <Hero />
      <div className="static-header__mobile">
        {window.innerWidth > 420 ? null : hideOnScroll ? <StaticHeader/> : null}
      </div>
      <DescargaComprobante />
      <Solutions />
      <Nosotros />
      <ClientList />
    </div>
  );

}