// Dependencias

import React from 'react';
import './RespuestaNo.css';
import apiGeneral from '../../../../apiGeneral';
import api from '../../../../api';

// export default function RespuestaNo() {
//   return (
//     <div>
//       <p>
//         Estimado cliente.disculpe las molestias,le contactaremos lo más pronto
//          posible parta resolver su inconveniente.
//       </p>
//       <div>
//         <a href="/"><button >Cerrar</button></a>
//       </div>
//     </div>
//   );
// }

export default class RespuestaNo extends React.Component{
  constructor(props){
      super(props)
          this.state={
              form:{
                Id_Incidencia:props.RRespuesta.resultado["1"].Id_Incidencia,
                Id_ClienteProveedor: props.RRespuesta.resultado["1"].Id_ClienteProveedor,
                Nro_Contacto:props.RRespuesta.resultado["1"].Nro_Contacto,
                Nom_Contacto:props.RRespuesta.resultado["1"].Nom_Contacto,
                Detalle:props.RRespuesta.resultado["1"].Detalle,
                Respuesta:props.RRespuesta.resultado["1"].Respuesta,
                Obs_Incidencia:props.RRespuesta.resultado["1"].Obs_Incidencia,
                Cod_UsuarioIncidencia:props.RRespuesta.resultado["1"].Cod_UsuarioIncidencia,  
                Pregunta1:0,
                Pregunta2:0,
                Pregunta3:'',
                Cod_Estado:'001',
                Cod_UsuarioAct:'Web',
              },
              data:{
                resultado:[]
             },
          }
      this.handleChange=this.handleChange.bind(this);
      this.handleSubmit=this.handleSubmit.bind(this);
  }

  handleChange(e){
    this.setState({
      form:{
        ...this.state.form,
        [e.target.name]:e.target.value,
      },
    });
  }

  async handleSubmit(e){
      e.preventDefault();

      try{
        const Rincidencia=this.state.form.Id_Incidencia;
        const Rpregunta1=this.state.form.Pregunta1;
        const Rpregunta2=this.state.form.Pregunta2;
        const Rpregunta3=this.state.form.Pregunta3;
        const Rcod_Estado=this.state.form.Cod_Estado;
        const Rcod_Usuario=this.state.form.Cod_UsuarioAct;

        const variables={
          procedure:'USP_SOP_INCIDENCIAS_G_Encuesta',		
          parametros:[
            {nom_parametro:'Id_Incidencia',valor_parametro:Rincidencia },
            {nom_parametro:'Pregunta1',valor_parametro:Rpregunta1},
            {nom_parametro:'Pregunta2',valor_parametro:Rpregunta2},
            {nom_parametro:'Pregunta3',valor_parametro:Rpregunta3},
            {nom_parametro:'Cod_Estado',valor_parametro:Rcod_Estado},
            {nom_parametro:'Cod_UsuarioAct',valor_parametro:Rcod_Usuario}     
          ]
        }
        // console.log(variables);
        const data= await apiGeneral.IncidenciaCliente.guardarIncidencia(variables);
        // console.log(data);
        if(data.message==="datoGuardado"){
          // this.state.length=1;
          // window.location.href = '/'; 
          const datos={
            Id_Incidencia:Rincidencia,
            Id_ClienteProveedor: this.state.form.Id_ClienteProveedor,
            Nro_Contacto:this.state.form.Nro_Contacto,
            Nom_Contacto:this.state.form.Nom_Contacto,
            Detalle:this.state.form.Detalle,
            Respuesta:this.state.form.Respuesta,
            Obs_Incidencia:this.state.form.Obs_Incidencia,
            Cod_UsuarioIncidencia:this.state.form.Cod_UsuarioIncidencia,
            Pregunta1:Rpregunta1,
            Pregunta2:Rpregunta2,
            Pregunta3:Rpregunta3,
            Cod_Estado:this.state.form.Cod_Estado
          }
            const emailEncuesta= await api.usuario.enviarCorreoEncuesta(datos);
              if(emailEncuesta.message=="EnviadoEmail"){
                window.location.href = '/encuesta'; 
                    
              }
              else{
                // this.showErrorEmail();
                window.location.href = '/encuesta'; 
                console.log("no se pudo enviar el mensaje")
              }
  
        }
        this.setState({
          data:{resultado:[].concat(this.state.data,data.resultado)}
        });
      }catch(error){
        console.log('Error',error)
        
      }
  }

  render(){
    
      return (
        <div className="FormPreguntas">
            <p>
              Estimado cliente.disculpe las molestias,le contactaremos lo más pronto
              posible parta resolver su inconveniente.
            </p>
            <div>
             <p>4.Déjame un comentario</p>
             <textarea name="Pregunta3" value={this.state.form.Pregunta3} onChange={this.handleChange}></textarea>
           </div>
            <div>
              {/* <a href="/" onClick={this.handleSubmit}>Cerrar</a> */}
              <button onClick={this.handleSubmit}>Enviar</button>
            </div>
        </div>
      );
    }
}