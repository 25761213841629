// Dependencias
import React from 'react';
import './FormPreguntas.css';
// import FormPreguntas3 from './FormPreguntas3';
import apiEncuesta from '../../../../apiGeneral';
import api from '../../../../api';
import icoMal from   '../imagenes/icoMal.png';
import icoMuyMal from '../imagenes/icoMuyMal.png';
import icoMuySatisfecho from '../imagenes/icoMuySatisfecho.png';
import icoRegular from '../imagenes/icoRegular.png';
import icoSatisfecho from '../imagenes/icoSatisfecho.png';
import RespuestaSi from '../RespuestaSi/RespuestaSi';

export default class FormPreguntas extends React.Component{
  constructor(props){
    super(props)
    this.state={
      mostrar:false,
      form:{
        Id_Incidencia:props.RRespuesta.resultado["1"].Id_Incidencia, 
        Id_ClienteProveedor: props.RRespuesta.resultado["1"].Id_ClienteProveedor,
        Nro_Contacto:props.RRespuesta.resultado["1"].Nro_Contacto,
        Nom_Contacto:props.RRespuesta.resultado["1"].Nom_Contacto,
        Detalle:props.RRespuesta.resultado["1"].Detalle,
        Respuesta:props.RRespuesta.resultado["1"].Respuesta,
        Obs_Incidencia:props.RRespuesta.resultado["1"].Obs_Incidencia,
        Cod_UsuarioIncidencia:props.RRespuesta.resultado["1"].Cod_UsuarioIncidencia,
        Pregunta1:0,
        Pregunta2:0,
        Pregunta3:'',
        Cod_Estado:'005',
        Cod_UsuarioAct:'Web',        
    },
    data:{
      resultado:[]
      },
    consulta:{
      message:undefined
    },
  };
    this.handleChange=this.handleChange.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this);
  }
  handleChange(e){
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
    this.setState({mostrar:false})

  }

  async handleSubmit(e){
      e.preventDefault();

      try{
        const RIncidencia=this.state.form.Id_Incidencia;
        const RPregunta1=this.state.form.Pregunta1;
        const RPregunta2=this.state.form.Pregunta2;
        const RPregunta3=this.state.form.Pregunta3;
        const RCod_Estado=this.state.form.Cod_Estado;
        const RCod_Usuario=this.state.form.Cod_UsuarioAct;
        const variables={
          // procedure:'USP_SOP_INCIDENCIAS_G_PREGUNTAS',	
          procedure:'USP_SOP_INCIDENCIAS_G_Encuesta',			
          parametros:[
            {nom_parametro:'Id_Incidencia',valor_parametro:RIncidencia },
            {nom_parametro:'Pregunta1',valor_parametro:RPregunta1 },
            {nom_parametro:'Pregunta2',valor_parametro:RPregunta2},
            {nom_parametro:'Pregunta3',valor_parametro:RPregunta3},
            {nom_parametro:'Cod_Estado',valor_parametro:RCod_Estado},
            {nom_parametro:'Cod_UsuarioAct',valor_parametro:RCod_Usuario}       
          ]
        
      }
      const data= await apiEncuesta.IncidenciaCliente.guardarIncidencia(variables);
    
      if(data.message==="datoGuardado"){
      
      const datos={
        Id_Incidencia:RIncidencia,
        Id_ClienteProveedor: this.state.form.Id_ClienteProveedor,
        Nro_Contacto:this.state.form.Nro_Contacto,
        Nom_Contacto:this.state.form.Nom_Contacto,
        Detalle:this.state.form.Detalle,
        Respuesta:this.state.form.Respuesta,
        Obs_Incidencia:this.state.form.Obs_Incidencia,
        Cod_UsuarioIncidencia:this.state.form.Cod_UsuarioIncidencia,
        Pregunta1:RPregunta1,
        Pregunta2:RPregunta2,
        Pregunta3:RPregunta3,
        Cod_Estado:this.state.form.Cod_Estado
      }
        const emailEncuesta= await api.usuario.enviarCorreoEncuesta(datos);
          if(emailEncuesta.message=="EnviadoEmail"){
            window.location.href = '/encuesta'; 
                
          }
          else{
            // this.showErrorEmail();
            window.location.href = '/encuesta'; 
            console.log("no se pudo enviar el mensaje")
          }
        
        // window.location.href = '/encuesta'; 

      }
      
      this.setState({
        consulta:{message:[].concat(this.state.data,data.message)},
        data:{resultado:[].concat(this.state.data,data.resultado)},mostrar:true 
      });
   

      }catch(error)
      {
        console.log('Error',error)
        alert('revise los datos porfavor hubo un error');
        this.setState({mostrar:false});

      }

  }
  render(){
    return(
    
      <div className="FormPreguntas">
      <div className="FormPreguntas__bloque1">
         <form onSubmit={this.props.onSubmit} > 
           <div >
             <div>
               <p>2.¿ Cuál es su nivel de satisfacción con el asesor que atendió su solicitud?</p>
             </div>
             <div>
                 
                  <div id="Pregunta1" name="Pregunta1"  className="FormPreguntas_bloque1-5columnas" className="FormPreguntas_bloque1-5columnas" value={this.state.form.Pregunta1} onChange={this.handleChange} >
                      <div className="FormPreguntas_bloque1-filas"><img src={icoMuyMal}/><input type="radio" name="Pregunta1" value={1} onChange={this.handleChange}/>Muy Mal </div>
                      <div className="FormPreguntas_bloque1-filas"><img src={icoMal}/><input type="radio" name="Pregunta1" value={2} onChange={this.handleChange} /> Mal </div>
                      <div className="FormPreguntas_bloque1-filas"><img src={icoRegular}/><input type="radio" name="Pregunta1" value={3} onChange={this.handleChange}/>Regular </div>
                      <div className="FormPreguntas_bloque1-filas"><img src={icoSatisfecho}/><input type="radio"name="Pregunta1" value={4} onChange={this.handleChange}/>Satisfecho</div>
                      <div className="FormPreguntas_bloque1-filas"><img src={icoMuySatisfecho}/><input type="radio" name="Pregunta1" value={5}/>Muy Satisfecho </div>
                  </div>
                 
               </div>

           <div >
             <div>
               <p>3.¿Considera Usted que la velocidad de mi respuesta fue?</p>
             </div>

              <div >
               
                <div id="Pregunta2" name="Pregunta2"   className="FormPreguntas_bloque1-5columnas" value={this.state.form.Pregunta2} onChange={this.handleChange}>
                
                    <div className="FormPreguntas_bloque1-filas"><img src={icoMuyMal}/><input type="radio" name="Pregunta2" value={1}  onChange={this.handleChange}/>Muy lento</div> 
                    <div className="FormPreguntas_bloque1-filas"><img src={icoMal}/><input type="radio" name="Pregunta2" value={2} onChange={this.handleChange} />Lento</div> 
                    <div className="FormPreguntas_bloque1-filas"><img src={icoRegular}/><input type="radio" name="Pregunta2" value={3} onChange={this.handleChange} />Regular</div> 
                    <div className="FormPreguntas_bloque1-filas"><img src={icoSatisfecho}/><input type="radio" name="Pregunta2" value={4} onChange={this.handleChange} />Rápido</div> 
                    <div className="FormPreguntas_bloque1-filas"><img src={icoMuySatisfecho}/><input type="radio" name="Pregunta2" value={5} onChange={this.handleChange}/>Muy rápido</div> 
                </div>
               
              </div>

              </div>
           <div>
             <p>4.Déjame un comentario</p>
             <textarea name="Pregunta3" value={this.state.form.Pregunta3} onChange={this.handleChange}></textarea>
           </div>
           </div>
         </form>
         <div>
           <button onClick={this.handleSubmit}>Enviar</button>
         </div>
          {/* <RespuestaSi
          enviado={this.state.mostrar}
          RRespuesta={this.state.consulta}
          /> */}
       </div>
       </div>
    )
  }

}















