/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resources
import Header from './1-Header/Header';
import Body from './2-Body/Body';
import Footer from './3-Footer/Footer';
import './Home.css';

export default function Home() {
  return (
    <section className="Home">
      <Header />
      <Body />
      <Footer />
    </section>
  );
}
