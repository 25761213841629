// Dependencias

import React from 'react';
import Header from '../Home/1-Header/Header';
import Body from './Body/Body';
import Footer from '../Home/3-Footer/Footer';
export default function EmpresaObligada(){
    return(
      <section>
          <Header />
          <Body />
          <Footer />
      </section>
    )
}