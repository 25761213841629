import React from 'react';
import pdf from "./PDF/MigracióndeBasedeDatosaMongoDB.pdf"
import { post } from 'axios';  
import api from '../../../../api';

import "./Novedades.css";
function Visualizar(archivo){
    var url='https://apis.consultasruc.com:4000/api/AArchivo/listar/';
    url+=archivo
    return document.getElementById('pdf').src = url;
}
 export default class Novedades extends React.Component{
    constructor(props) {        
        super(props);    

        this.state = {    
            file: '',
            tabla:{
                resultado:[],
            },
        };    
    }  
    
    componentDidMount(){
        this.fetchTabla();
    }

    fetchTabla = async () =>{
   
      try{
        const tabla=await api.SubidaArchivos.listarArchivos();
        this.setState({
        tabla:{ resultado:[].concat(this.state.tabla.resultado,tabla.resultado)}});
        console.log(tabla);
      } catch(error){
        //   this.setState({error:error});
        console.log(error);
      }     
    }
    
    async submit(e) {    
            e.preventDefault();    
            const url = `https://apis.consultasruc.com:4000/api/AArchivo/subidaArchivo`;    
            const formData = new FormData();    
            formData.append('file', this.state.file);    
                const config = {    
                    headers: {    
                            'content-type': 'multipart/form-data',    
                    },    
            };    
            return post(url, formData, config);    
    }    
    setFile(e) {    
            this.setState({ file: e.target.files[0] });  
           
    }    
    render(){
        if(this.state.loading===false){
            return 'Loading ...';
        }
        if(this.state.error){
            return `Error:${this.state.error}`

        }
        return(
            <div className="novedades">
                <h1 className="title--large">Novedades</h1>
                 {/*Fomulario para subida de archivos */}
                 <div>
                     <form  onSubmit={e => this.submit(e)} >
                         <div> 
                             <h3>
                                 Subida de Archivo
                                </h3>
                         </div>  
                   
                         <div>
                            <input type="file" onChange={e =>this.setFile(e) } name="File" />
                            <button className="button--darkblue" type="submit"> Subir Archivo </button>
                            
                         </div>
                     </form>
                 </div>

                <div className="novedades_visualizacion">
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Archivos</th> 
                                    <th className="text-right">Visualizar</th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                    {this.state.tabla.resultado.map(tabla=>{
                                        return(
                                            <tr>
                                                <td>{tabla}</td>
                                                <td>
                                                    <button  className="button--darkblue"onClick={() =>Visualizar(tabla)}>Visualizar</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <embed id="pdf" src={pdf} type="application/pdf" width="100%" height="600px" />
                    </div>

                </div>


            </div>
        )

    }
}