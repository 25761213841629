/* eslint-disable no-implied-eval */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import Form from '../../../../sharedcomponents/Form/Form';
import api from '../../../../api';

function FormRegistroUs() {
  // state that keeps values that will be used on the form
  const [formState, setFormState] = useState([]);
  useEffect(() => {
    // setting the initial value of the form state
    setFormState([
      {
        name: 'ruc',
        content: 'ruc',
        value: '',
      },
      {
        name: 'razon_social',
        content: 'razon_social',
        value:'',
      },
      {
        name: 'telefono',
        content: 'telefono',
        value: '',
      },
      {
        name: 'email',
        content: 'correo',
        value: '',
      },
      {
        formType: 'button',
        type: 'submit',
        content: 'Enviar',
      },
    ]);
  }, []);
  // function to update values of the form
  function onChange(e) {
    const newFormState = formState.map((item) => {
      // only past values of items that have value and name properties
      if (item.name !== undefined && item.value !== undefined) {
        if (item.name === e.target.name) {
          item.name = e.target.name;
          item.value = e.target.value;
        }
      }
      return item;
    });
      // update form state with the new values
    setFormState(newFormState);
  }
  // function to submit data of the form
  async function onSubmit(e) {
    e.preventDefault();

    const bodyData = {};
    // eslint-disable-next-line array-callback-return
    formState.map((item) => {
      if (item.name !== undefined && item.value !== undefined) {
        bodyData[item.content] = item.value;
      }
    });
    api.usuario.enviarCorreoRegistro(bodyData);

    await setTimeout('location.reload(true);', 800);
  }

  return (
    <Form method="POST" state={formState} onChangeEvent={onChange} onSubmitEvent={onSubmit} />
  );
}

export default FormRegistroUs;
