// Dependencias
import React from 'react';
import './RespuestaLlenada.css';
import llenado from './imagen/llenado.jpg'
export default function RespuestaLlenada(props) {

if(!props.consultado){
  return(null);
}
else{
        if(Object.keys(props.RIncidencia).length===1)
        {  
          if(props.RIncidencia.resultado["1"].Cod_Estado==="005")
          {
            return (
              <div>
                <h1>La encuesta ya ha sido registrada,</h1>
                <h1>Muchas gracias por su  opinión!!.</h1>
                <div>
                <img  src={llenado} alt='Encuesta Rellenada'/>
                </div>
              </div>
            );
          }
          else{
            return (
              <div>
                <p>Hubo un error en el registro de sus datos</p>
              </div>
            );
          }
        }
     }
}
