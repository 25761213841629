/* eslint-disable indent */
//Dependencies
import React from 'react';

//Resorces
import Header from '../../Home/1-Header/Header';
import Footer from '../../Home/3-Footer/Footer';
import gracias from './Imagen/gracias.jpg';
// import './PagEncuesta.css';

export default class EncuestaFinalizada extends React.Component{
  
render(){
 
  return(
    <section>
           <Header />
              
                <div className="PagEncuesta">
                  <div className="PagEncuesta__FormEncuesta">
                  <div>
                        <h1>Has finalizado la encuesta,</h1>
                        <h1>Muchas gracias por su valiosa ayuda!!.</h1>
                        <div>
                        <img  src={gracias} alt='Encuesta finalizada'/>
                        </div>
                    </div>
                  </div>
              </div> 
          <Footer />
        </section>
  )
}

}