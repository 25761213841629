// Dependencias
import React from 'react';
import './RespuestaCaducidad.css';
import Agotado from './imagen/relojArena.jpg'
import { useState } from 'react';
import FormEncuestaDatos from '../FormEncuestaDatos/FormEncuestaDatos';

import '../FormEncuesta/FormEncuesta.css'

export default function RespuestaCaducidad(props) {

const [pEncuesta, setEncuesta]=useState('');
const [pregunta1, setpregunta1] = useState('');
const [mostrar, setmostrar]=useState(true);


if(!props.consultado){
  return(null);
}
else{
        if(Object.keys(props.RIncidencia).length===1)
        {  
          
          const Fecha= new Date(props.RIncidencia.resultado["1"].Fecha_Inicia).toLocaleDateString();
            // console.log(props.RIncidencia);
            // const array=[];
            // array.push(props.RIncidencia);
            // console.log(array);
            return (
              <div>
                {/* <h1>Hubo en error al constestar la encuesta</h1> */}
                <h1>Esta encuesta ya caducó</h1>
                  <div>
                        <div >
                          <p >Su opinión es muy importante</p>
                          <div>
                          <div>
                              <p>1. ¿Desea Rellenar la encuesta?</p>
                          </div>
                          <div id="pEncuesta"  value={pEncuesta} onChange={(e)=> setEncuesta(e.target.value)}>
                            <div>
                                <label>
                                  <input type="radio" name="pEncuesta"  value="SI" />
                                      SI
                                  </label>
                            </div>
                          </div>
                          <div>
                                {pEncuesta==="SI"? 
                                <FormEncuestaDatos
                                 consultado={true}
                                 RIncidencia={props.RIncidencia} />:null}
                            </div>
                          </div>
                      </div>  
                  </div>
                
              </div>
            );
          }
          else{
            return (
              <div>
                <p>Hubo un error en el registro de sus datos</p>
              </div>
            );
          }
        }
     }

