/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resources
import SolutionsCard from './SolutionsCard/SolutionsCard';
import grifos from './imagen/grifos-200x200.png';
import hoteles from './imagen/hoteless-200x200.png';
import restaurantes from './imagen/restaurantes-200x200.png';
import farmacia from './imagen/farmacia-200x200.png';
import telecomunicaciones from './imagen/telecomunicaciones-200x200.png';
import market from './imagen/stores-200x200.png';
import './Card_Solutions.css';

export default function CardSolution() {
  const cardsSolutions = [
    {
      type: 1,
      image: grifos,
      responsiveImages: grifos,
      alt: 'grifo',
      title: 'ESTACIONES DE SERVICIO',
      items: [
        {
          value: 'Control de serafin, contometros y varillaje.',
        },
        {
          value: 'Registro de inventario para combustible (RIC).',
        },
        {
          value: 'Interfaz personalizada de surtidores.',
        },
        {
          value: 'Liquidación diaria SUNAT.',
        },
        {
          value: 'Gestión de Licitaciones.',
        },
      ],
    },
    {
      type: 1,
      image: hoteles,
      responsiveImages: hoteles,
      alt: 'hotel',
      title: 'HOTELERIA',
      items: [
        {
          value: 'Gestión de precios por volumen.',
        },
        {
          value: 'Emisión fácil, rápida e intuitiva',
        },
        {
          value: 'Certificado Digital Incluido',
        },
        {
          value: 'Multi-dispositivos',
        },
        {
          value: 'Soporte vía correo electrónico',
        },
      ],
    },
    {
      type: 1,
      image: restaurantes,
      responsiveImages: restaurantes,
      alt: 'restaurantes',
      title: 'RESTAURANTES',
      items: [
        {
          value: 'Gestión de comandas.',
        },
        {
          value: 'Control de mesa en tiempo real.',
        },
        {
          value: 'Gestión de reserva y delivery.',
        },
      ],
    },
    {
      type: 1,
      image: farmacia,
      responsiveImages: farmacia,
      alt: 'farmacia',
      title: 'FARMACIA',
      items: [
        {
          value: 'Gestión de lotes y Vencimiento.',
        },
        {
          value: 'Venta por Lote.',
        },
        {
          value: 'Ubicación en almacén.',
        },
      ],
    },
    {
      type: 1,
      image: telecomunicaciones,
      responsiveImages: telecomunicaciones,
      alt: 'Telecomunicaciones',
      title: 'TELECOMUNICACIONES',
      items: [
        {
          value: 'Control de productos y serie.',
        },
        {
          value: 'Gestión de garantías.',
        },
        {
          value: 'Inventario por serie.',
        },
        {
          value: 'Antigüedad de producto.',
        },
      ],
    },
    {
      type: 1,
      image: market,
      responsiveImages: market,
      alt: 'market',
      title: 'MARKET',
      items: [
        {
          value: 'Gestión de precios por volumen.',
        },
        {
          value: 'Búsqueda de  producto de descripción, código de barras y accesos directos.',
        },
        {
          value: 'Interfaz de punto de venta táctil.',
        },
        {
          value: 'Gestión de inventarios.',
        },
        {
          value: 'Programa de Fidelizacion.',
        },
      ],
    },
  ];

  return (
    <section id="Solutions" className="Card_Solutions">
      <div className="card-solution-grid">
        <div className="Card_Solutions_title">
          <p className="Card_Solutions_p">Nuestras soluciones te ayudaran a potenciar tu negocio.</p>
        </div>
      </div>
      <div className="Card_Solutions__info">
        {cardsSolutions.map((solution) => (
          <SolutionsCard
            key={cardsSolutions.indexOf(solution)}
            type={solution.type}
            image={solution.image}
            responsiveImages={solution.responsiveImages}
            title={solution.title}
            items={solution.items}
          />
        ))}
      </div>
    </section>
  );
}
