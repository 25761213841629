/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Resources
import FormField from '../FormField/FormField';

import './Form.css';

export default function Form(
  {
    method = 'GET',
    styletype = 1,
    state = [],
    onChangeEvent,
    onSubmitEvent,
  },
) {
  if (styletype !== 1) {
    return (
      <div className="form-section__2">
        <form className="form__2" method={method} onSubmit={onSubmitEvent}>
          {state.map((item) => (
            <FormField
              key={state.indexOf(item)}
              type={item.type}
              styletype={2}
              formType={item.formType}
              name={item.name}
              content={item.content}
              value={item.value}
              handleChange={onChangeEvent}
            />
          ))}
        </form>
      </div>
    );
  }
  return (
    <div className="form-section__1">
      <form className="form__1" method={method} onSubmit={onSubmitEvent}>
        {state.map((item) => (
          <FormField
            key={state.indexOf(item)}
            styletype={1}
            type={item.type}
            formType={item.formType}
            name={item.name}
            content={item.content}
            value={item.value}
            handleChange={onChangeEvent}
          />
        ))}
      </form>
    </div>
  );
}
Form.propTypes = {
  method: PropTypes.string,
  state: PropTypes.array,
  onChangeEvent: PropTypes.func.isRequired,
  onSubmitEvent: PropTypes.func.isRequired,
};
