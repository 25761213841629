import React from 'react';
//Resources 
import Header from '..//Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import LibroReclamaciones from './FormReclamaciones/LibroReclamaciones';

export default function PagLibroReclamaciones(){
    return(
      <section>
          <Header/>
            <LibroReclamaciones/>
          <Footer/>
      </section>
    )
}
