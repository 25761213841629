/* eslint-disable jsx-a11y/iframe-has-title */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import FormContactUs from './FormContactUs/FormContactUs';
import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import './ContactUs.css';

export default function ContactUs() {
  return (
    <div>
      <Header />
      <div className="contactus__top">
        <div className="contactus">
          <div>
            <h1>Formulario de Contacto</h1>
            <hr className="contactus__hrline" />
          </div>
          <div>
            <FormContactUs />
          </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3879.1117277092367!2d-71.948686!3d-13.5287343!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf41b15d3b4d18f22!2sPale%20Consultores%20EIRL!5e0!3m2!1ses!2spe!4v1573849829958!5m2!1ses!2spe" width="100%" height="400px" frameborder="0"  allowfullscreen=""></iframe>
      </div>
      <Footer />
    </div>
  );
}
