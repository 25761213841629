import React from 'react';
import FormRegistroUs from './FormRegistroUs/FormRegistroUs';
import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import './RegistroUs.css';

export default function RegistroUs(){
    return(
    <div>
        <Header />
        <div className="registro__top">
          <div className="registro">
            <div>
              <h1>Formulario de Registro</h1>
              <hr className="registro__hrline" />
            </div>
            <div>
              <FormRegistroUs />
            </div>
            <div/>
          </div>
        </div>
        <Footer />
    </div>

    );

}