/* eslint-disable no-console */
import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../Home/1-Header/Header';
import Footer from '../../Home/3-Footer/Footer';
import BlogReadMoreCard from './BlogReadMoreCard/BlogReadMoreCard';
import img1 from '../BlogCard/images/facturacion.png';
import img2 from '../BlogCard/images/electronica.jpg';
import img3 from '../BlogCard/images/medio-ambiente.jpg';
import img4 from '../BlogCard/images/img2.jpg';

export default function BlogReadMore() {
  const { blogId } = useParams();
  const blogreadmorecard = [
    {
      id: '1',
      title: 'Beneficios de implementar un sistema de punto de venta',
      date: '30/04/2019',
      image: img1,
      parrafo: [
        {
          value: 'Un sistema de punto de venta es un software que gestiona el proceso de venta mediante una interfaz accesible para los vendedores o compradores. Un único sistema informático permite la creación e impresión del ticket o factura electrónica —con los detalles de las referencias y precios— de los artículos vendidos, actualiza los cambios en el nivel de existencias de mercancías (stock) en la base de datos.',
        },
        {
          value: 'Permite a cualquier negocio tener sistematizado y organizado el establecimiento brindando un mejor servicio a clientes, además de agilizar los procesos de: ventas, compras, inventarios, créditos, licitaciones, cobranza, cotizaciones, entre otros.',
        },
        {
          value: 'Beneficios de tener un sistema en tu empresa',
        },
        {
          value: 'INTEGRACION:',
        },
        {
          value: 'Un sistema punto de venta es compatible con dispositivos externos como lectores de código de barras, impresoras, cajas registradoras de efectivo.',
        },
        {
          value: 'REDUCES ERRORES DE CAPTURA:',
        },
        {
          value: 'Buscas a tu cliente por el nro de RUC; para el producto, usas un escáner de códigos de barra ¡y listo! Así de simple, lo que sin duda vuelve al registro de venta una tarea mucho más sencilla y precisa.',
        },
        {
          value: 'MEJORAS TU CONTROL DE INVENTARIOS:',
        },
        {
          value: 'iFacturacion no se limita a las ventas, sino que impactan otras áreas como lo son los inventarios. Nuestro sistema te permite ver en tiempo real la cantidad de productos disponibles.Todo lo anterior, desde una misma pantalla y sin tener que contactar al equipo de almacenes o llamar a las demás sucursales.',
        },
        {
          value: 'SIMPLIFICAS TU CONTABILIDAD:',
        },
        {
          value: 'También la administración financiera y contable de tu empresa se verán beneficiadas, ya que al momento de realizar una venta, esta se captura automáticamente para ser mostrada en los reportes contables. En iFacturacion todos nuestros reportes son dinámicos y exportables a Excel, PDF y Word listo para ser enviado por correo al área contable para su declaración.',
        },
        {
          value: 'VENDES DESDE CUALQUIER LUGAR:',
        },
        {
          value: 'No todos los software de punto de venta aportan esta ventaja, pero si cuentas con iFacturacion y cualquier dispositivo con Internet, ¡conviertes tu teléfono, tablet o laptop en un terminal punto de venta al instante!',
        },
        {
          value: 'Gracias al poder de la nube, puedes registrar transacciones en cualquier momento y lugar. Ningún cliente potencial se te podrá escapar con la excusa de estar lejos de alguna de tus sucursales. ¡Llevas tu negocio contigo a todas partes!',
        },
      ],
    },
    {
      id: '2',
      title: 'Beneficios de implementar un sistema de punto de venta',
      date: '30/04/2019',
      image: img2,
      parrafo: [
        {
          value: 'La facturación electrónica, refiere la Sunat, mejora su productividad, al optimizar no solo sus procesos y sistemas de emisión, sino también de cobranza.',
        },
        {
          value: 'El uso de las facturas electrónicas representa un ahorro de hasta 67% en los costos de impresión, gastos de distribución y archivo en comparación con los comprobantes físicos , aseguró la Sunat.',
        },
        {
          value: 'Para las empresas, refiere el ente emisor, la facturación electrónica mejora su productividad, al optimizar no solo sus procesos y sistemas de emisión, sino también de cobranza pues están integrados como medio de pago electrónico, convirtiéndose en facturas negociables.',
        },
        {
          value: 'Otro de los beneficios de la facturación electrónica es su disponibilidad, puesto que se pueden consultar desde cualquier lugar, las 24 horas del día, en un ambiente seguro, teniendo el respaldo legal y validez garantizados.',
        },
        {
          value: 'Deducción en hoteles y restaurantes. A partir de este año, agregó la Sunat, los hoteles y restaurantes deben consignar en las boletas de venta electrónicas el DNI de su cliente, a fin de que pueda deducir el gasto para fines del Impuesto a la Renta. Si el cliente es extranjero, se deberá consignar su número de pasaporte.',
        },
        {
          value: 'Esta obligación, introducida en la última modificación al Reglamento de Comprobantes de Pago, permitirá que los trabajadores independientes y dependientes que ganen más de 7 UIT al año (S/ 29,400) puedan deducir el 15% del importe pagado por sus consumos, por lo que los restaurantes y hoteles deben tener adecuados sus sistemas.',
        },
        {
          value: 'En esa línea, el ente emisior precisó que ha iniciado una campaña para identificar los negocios que aún no están cumpliendo con la disposición comentada, pudiendo aplicar la sanción por emitir comprobantes de pago que no reúnen los requisitos y características establecidas en la normatividad vigente.',
        },
        {
          value: 'Finalmente, los comprobantes de pago electrónicos protegen el medio ambiente. Así, si una empresa emite al año 12,000 comprobantes electrónicos, contribuye directamente a evitar la tala de 57 árboles y ahorrar 180 mil litros de agua que se necesita para procesar la madera.',
        },
      ],
    },
    {
      id: '3',
      title: 'Factura electrónica contribuye con el medio ambiente',
      date: '30/04/2019',
      image: img3,
      parrafo: [
        {
          value: 'Una de las principales ventajas de la facturación electrónica es el ahorro en costos que otorga al no ser necesario comprar papel, imprimir y entregar facturas físicas. Pero ¿has pensado en el beneficio que esto representa para el planeta?',
        },
        {
          value: 'Anteriormente las empresas parecían no tener la ecología en su lista de prioridades, pero con el paso del tiempo y la problemática que el planeta ha presentado conforme el avance tecnológico e industrial, el factor ambiental y la responsabilidad social ha permeado en las empresas de todo el mundo. La preocupación social e institucional por el deterioro del medio ambiente se ha traducido entonces en presión hacia los negocios para que incorporen procesos y actividades mas respetuosos con su entorno. ',
        },
        {
          value: 'Existen muchos cambios que una empresa puede implementar para apoyar al medio ambiente, y uno de los primordiales es la implementación de un sistema de facturación electrónica. ',
        },
        {
          value: 'Esto quiere decir que entre más empresas se sumen a la facturación electrónica, el impacto ambiental será mayor en beneficio del planeta. Entendemos que el valor más importante en este sentido se genera en el proceso de recepción de facturas, ya que muchas empresas, que incluso facturan electrónicamente, conviven por mucho tiempo con la recepción de facturas en papel, las cuales muchas veces son fotocopiadas varias veces y distribuidas a diferentes usuarios para su aprobación, por esta razón no sólo te invitamos a sumarte a la facturación electrónica, sino a tener nuestros sistemas con iFacturacion. ',
        },
        {
          value: 'iFacturacion te permitirá almacenar todas tus facturas electrónicas en un mismo lugar, resguardarlas y dar acceso a tus clientes para poder llevar a cabo el procesamiento, administración y aprobación de estas facturas. Todo, sin necesidad de imprimirlas. Si alguno de los usuarios requiere la descarga o enviar por correo electrónico un comprobante en particular, puede hacerlo en sus archivos XML y PDF desde nuestra plataforma sin preocuparse por tener que imprimir o fotocopiar las mismas para compartirlas con quien lo requiera.',
        },
        {
          value: 'Te invitamos a unirte a la era de la facturación electrónica y a cuidar el medio ambiente junto con iFacturacion.',
        },
      ],
    },
    {
      id: '4',
      title: 'Factura electrónica en el Peru',
      date: '30/04/2019',
      image: img4,
      parrafo: [
        {
          value: 'Mediante la facturación electrónica tendrás la oportunidad de estar en una red de negocios, en donde todas las empresas podrán intercambiar documentos comerciales de manera electrónica. Ello les permitirá lograr mayor eficiencia en sus procesos logísticos y contables, ahorrar costos de almacenamiento, minimizar el riesgo de facturas erradas y ayudar en la protección del medio ambiente. Por lo cual Desarrollamos una solución integral de facturación electrónica, fácil de usar y muy segura pensada exclusivamente en las necesidades del empresario peruano.',
        },
        {
          value: 'Una factura electrónica es, ante todo, una factura. Es decir, tiene los mismos efectos legales que una factura en papel. Recordemos que una factura es un justificante de la prestación de servicios.',
        },
        {
          value: 'Una factura electrónica es una factura que se expide y recibe en formato electrónico.La facturación electrónica se ha convertido en una pieza clave en la modernización de la actividad empresarial, razón por la cual debes contemplarla hoy como la mejor opción para potenciar tu negocio.',
        },
      ],
    },
  ];
  if (blogreadmorecard[0].id === blogId.slice(1)) {
    return (
      <section>
        <Header />
        <BlogReadMoreCard
          id={blogreadmorecard[0].id}
          title={blogreadmorecard[0].title}
          date={blogreadmorecard[0].date}
          image={blogreadmorecard[0].image}
          parrafo={blogreadmorecard[0].parrafo}
        />
        <Footer />
      </section>
    );
  }
  if (blogreadmorecard[1].id === blogId.slice(1)) {
    return (
      <section>
        <Header />
        <BlogReadMoreCard
          id={blogreadmorecard[1].id}
          title={blogreadmorecard[1].title}
          date={blogreadmorecard[1].date}
          image={blogreadmorecard[1].image}
          parrafo={blogreadmorecard[1].parrafo}
        />
        <Footer />
      </section>
    );
  }
  if (blogreadmorecard[2].id === blogId.slice(1)) {
    return (
      <section>
        <Header />
        <BlogReadMoreCard
          id={blogreadmorecard[2].id}
          title={blogreadmorecard[2].title}
          date={blogreadmorecard[2].date}
          image={blogreadmorecard[2].image}
          parrafo={blogreadmorecard[2].parrafo}
        />
        <Footer />
      </section>
    );
  }
  if (blogreadmorecard[3].id === blogId.slice(1)) {
    return (
      <section>
        <Header />
        <BlogReadMoreCard
          id={blogreadmorecard[3].id}
          title={blogreadmorecard[3].title}
          date={blogreadmorecard[3].date}
          image={blogreadmorecard[3].image}
          parrafo={blogreadmorecard[3].parrafo}
        />
        <Footer />
      </section>
    );
  }
}
