// Dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Resources
import './CardInformation.css';

export default function CardInformation(
  {
    image = <FontAwesomeIcon />,
    subtitle = 'lorem',
    text = 'lorem',
  },
) {
  return (
    <div className="cardInformation">
      <div className="cardInformation_ico">
        {image}
      </div>
      <div>
        <h1 className="subtitle__text">{subtitle}</h1>
        <hr className="card-information__line" />
        <p className="nosotros__text">{text}</p>
      </div>
    </div>
  );
}


CardInformation.propTypes = {
  type: PropTypes.number,
  serviceClass: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
};
