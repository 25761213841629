import React from 'react';
import Header from  '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import Novedades  from './Novedades/Novedades';

import './PagNovedades.css';

export default function PagNovedades(){
    return(
        <section className="Pagnovedades">
        <Header />
            {/* <Novedades /> */}
        <Footer />
        </section>
    );

}
