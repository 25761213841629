import React, {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './LibroReclamaciones.css'
import imagen from './libro_reclamaciones.png';
import logo from './LogoPaleSinfondo.png';
import api from '../../../../api';

function IdentificacionCliente(){
  const [page, setPage]= useState(0);
  
  return(
    <div className='FormNuevoReclamaciones'>
      <div className='LogoLibroReclamaciones'>
        <div>
        <label className='tituloReclamaciones'>Libro de <br/>Reclamaciones</label>
        </div>
        <div>
          <img src={imagen} alt='libro reclamaciones' height={74} width={200}/>
        </div>
      </div>
{/**/}
      <div className='PruebaDivR'> 

        <div className='RuCReclamaciones'>
          <img src={logo} alt='libro reclamaciones' height={74} width={200}/>
          <label>Pale Consultores E.I.R.L</label>
        </div>

        <div className=''>                                                                                                                                                                                                                                              
          <div className='documento'>
            <div className='Tdocumento'>
              <label>Tipo Documento <br/>
                <select 
                  name="resProduServi"
                >  
                    <option name="resProduServi" value="Servicio">DNI</option>
                    <option name="resProduServi" value="Producto">RUC</option>
                    <option name="resProduServi" value="Producto">Pasaporte</option>
                    <option name="resProduServi" value="Producto">Carné de Extranjería</option>
                </select>
              </label>
            </div>

            <div className='Ndocumento'>
              <label>
                  Nro Documento: <br/>
                  <input  className= 'inputnro' value={"doc"} required/>
              </label>
            </div>
          </div>

          <div>
              Nombre / Razón Social: <br/>
              <input  className= '' value={"doc"} required/>
          </div>

          <div>
          <label>
                <input type="checkbox" />
                Soy menor de edad
              </label>
          </div>

          <div>
              Apoderado: <br/>
              <input  className= '' value={"doc"} required/>
          </div>

          <div>
            <label>
                Dirección: <br/>
                <input  className= '' value={"doc"} required/>
            </label>
          </div>

          <div className='documento'>
            <div className='Tdocumento'>
              <label>
                  Celular: <br/>
                  <input  className= '' value={"doc"} required/>
              </label>
            </div>

            <div className='Ndocumento'>
              <label>
                  Correo electrónico: <br/>
                  <input  className= '' value={"doc"} required/>
              </label>
            </div>
          </div>

          <div>
            <input type="checkbox" />
            Claúsula de tratamiento de datos
          </div>           
        </div>
      </div> 
    </div>
  );
}

export default IdentificacionCliente;