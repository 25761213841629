// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import './DownloadVoucher.css';

import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
// import FormVoucher from './FormVoucher/FormVoucher';
import FormDownload from '../VoucherDownload/FormDownload';
import Mantenimiento from "./Web12802.png";
export default function DownloadVoucher() {
  return (
    <div>
      <Header />
      <div className="form__voucher">
        {/* <img src={Mantenimiento} alt="en mantenimiento"/> */}
        <FormDownload />
      </div>
      <Footer />
    </div>
  );
}
