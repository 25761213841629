/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import './Blogcard.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faClock } from '@fortawesome/free-solid-svg-icons';

export default function BlogCard(
  {
    id = 1,
    title = 'lorem',
    date = 'lorem',
    image = 'lorem',
    description = 'lorem',
  },
) {
  const { url } = useRouteMatch();
  return (
    <section className="blog-section">
      <div className="blog-section__body">
        <h1 className="blog-section__tittle" >{title}</h1>
        <div className="blog-section-body__image-description">
          <div className="blog-section-body__image-description__date">
            <FontAwesomeIcon icon={faClock} />
            <p>{date}</p>
          </div>
        </div>
        <img src={image} alt="imagen1" className="blog-section-body__image-adapt"></img>
        <p className="blog-section-body__description">{description}</p>
        <Link className="blog-section-body__button" to={`${url}/leermas/:${id}`}>Leer Mas</Link>
      </div>
    </section>
  );
}
