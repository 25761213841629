const BASE_URL = 'https://api.culqi.com/v2';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const randomNumber = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1)) + min;

const simulateNetworkLatency = (min = 30, max = 1500) => delay(randomNumber(min, max));

async function callApi(endpoint, options = {}) {
  await simulateNetworkLatency();

  // eslint-disable-next-line no-param-reassign
  options.headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer sk_test_0e519836516980b5'
  };

  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

const api = {
    cargos: {
    crearCargo(datos) {
      return callApi('/charges', { 
        method: 'POST',
        body: JSON.stringify(datos),
      });
    },
    consultarCargo(id) {
        return callApi(`/charges/${id}`, {
          method: 'GET'
        });
      },
  },
 
 
};

export default api;
