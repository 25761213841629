 /* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React, { useState, useEffect } from 'react';

// Resources
import BurgerMenu from './BurgerMenu/BurgerMenu';
import MenuList from './MenuList/MenuList';
import StaticHeader from './StaticHeader/StaticHeader';

import './Header.css';
// eslint-disable-next-line import/no-duplicates
import logoPalePrincipal from '../../../../images/Logo_720.png';
// eslint-disable-next-line import/no-duplicates
import logoPaleNegativo from '../../../../images/Logo_monocromático blanco720.png';

export default function Header() {
  // Get the rem unit value in pixels
  const remVal = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const comparationvalue = window.innerHeight - 99;

  // Boolean state to change the styles of the navigation bar
  const [shouldBgChange, setShouldBgChanged] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);

  // Effect to check if the navigation bar should change styles
  useEffect(() => {
    function onScroll() {
      const changeBackground = window.scrollY > (window.innerHeight - 5.5 * remVal);
      const hideNavBar = window.scrollY > (window.innerHeight - comparationvalue);

      if (shouldBgChange !== changeBackground) {
        setShouldBgChanged(changeBackground);
      }
      if (shouldHide !== hideNavBar) {
        setShouldHide(hideNavBar);
      }
    }
    document.addEventListener('scroll', onScroll);
  });

  return (
    <header className={`header ${shouldBgChange ? 'header--positive' : ''}`}>
      <div className={`'' ${shouldHide ? 'static-header-hidden' : ''}`}>
        {/* <StaticHeader />  */}
        {window.innerWidth > 420 ? <StaticHeader/> : null }
      </div>
      <nav className="header__navbar topnav">
        <a href="/" className="header__brand">
          <img className="header__brand-image" src={shouldBgChange && window.innerWidth > 1011 ? logoPalePrincipal : logoPaleNegativo} alt="logoPale" />
        </a>
        <BurgerMenu />
        <div className={`header__menu ${shouldBgChange && window.innerWidth > 1011 ? 'header__menu--positive' : ''}`}>
          <MenuList />
        </div>
      </nav>
    </header>
  );
}