/* eslint-disable indent */
//Dependencies
import React from 'react';

//Resorces
import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import FormEncuestaDatos from './FormEncuestaDatos/FormEncuestaDatos';
import apigeneral from '../../../apiGeneral';
// import apigeneral from '../../../api';
import './PagEncuesta.css';
import PageLoading from './FormEncuesta/PageLoading';
import RespuestaLlenada from './RespuestaEncuestaLlenada/RespuestaLlenada';
import RespuestaCaducidad from './TiempoCaducidad/RespuestaCaducidad';

export default class PagEncuesta extends React.Component{
  constructor(props){
    super(props)
   
    this.state={isVerified:false};
    
    this.state={
      // id:"",
     id:this.props.match.params.idEncuesta,
     loading:true,
      mostrar:false,
      mostrarMensaje:false,
      mostrarCaducidad:false,
      consulta:{
        resultado:[]
      },
    };
   
  }
   
  componentDidMount() {
    this.fetchData();
  }

  async fetchData(){
 
    this.setState(
      { loading: true, 
        error: null });

    try {
      // const codificado=btoa(this.state.id);
      // console.log(codificado);

      // const descodificado=atob(this.state.id);
      // console.log(descodificado);
   
      // const consultar = await apigeneral.IncidenciaCliente.traerIncidencia(descodificado);
      const consultar = await apigeneral.IncidenciaCliente.traerIncidencia(this.state.id);
      // console.log(consultar);
      var dateF = new Date(consultar.resultado["0"].Fecha_Finaliza);
      let semanaMiliSegundos=1000*60*60*24*7;//mas 7 dias para responder la encuesta
      let suma=dateF.getTime()+semanaMiliSegundos;
      const Fin= new Date(suma);
     
      var fechaActual=new Date();
      
      if(fechaActual.getTime()<= Fin.getTime()){
        //Tiempo valido para responder la encuesta
        if(consultar.resultado["0"].Cod_Estado==="005")
        {
          this.setState({mostrar:false,loading:false,mostrarMensaje:true,
             consulta:{resultado:[].concat(this.state.consulta,consultar.resultado)}});

        }
        else{
          
          this.setState({mostrar:true, loading: false,
            consulta:{resultado:[].concat(this.state.consulta,consultar.resultado)}});
            console.log(this.state.consulta.resultado);
        }

      }
      else{
        
        //Tiempo expirado para rellenar la encuesta
        console.log("mayor a la fecha");
        if(consultar.resultado["0"].Cod_Estado==="005")
        {
          this.setState({mostrar:false,loading:false,mostrarMensaje:true,
            consulta:{resultado:[].concat(this.state.consulta,consultar.resultado)}});

        }else{
          this.setState({mostrar:false,loading:false,mostrarMensaje:false,mostrarCaducidad:true,
            consulta:{resultado:[].concat(this.state.consulta,consultar.resultado)}});
        }
       
        
      }
      
    } catch (error) {
      this.setState({ mostrar: false, error: error, loading: false, });
    }
  };

render(){
  if (this.state.loading) {
    return <PageLoading />;
  }
  return(
    <section>
           <Header />
              
                <div className="PagEncuesta">
                  <div className="PagEncuesta__FormEncuesta">
                      <FormEncuestaDatos
                            consultado={this.state.mostrar}
                            RIncidencia={this.state.consulta}
                      />
                      <RespuestaLlenada 
                            consultado={this.state.mostrarMensaje}
                            RIncidencia={this.state.consulta}
                      />
                      <RespuestaCaducidad
                        consultado={this.state.mostrarCaducidad}
                        RIncidencia={this.state.consulta}
                      />
                  </div>
              </div> 
          <Footer />
        </section>
  )
}

}