/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faClock } from '@fortawesome/free-solid-svg-icons';

import "./BlogReadMoreCard.css"

import Legislacion from '../../../Legislation/Legislation'

export default function BlogReadMoreCard(
  {
    id = 1,
    title = 'lorem',
    date = 'lorem',
    image = 'lorem',
    parrafo = [],
  },
) {
  return (
    <section>
      <div className="blogmore-section__body">
        <h1>{title}</h1>
        <div className="blogmore-section-body__image-description">
          <div className="blogmore-section-body__image-description__date">
            <FontAwesomeIcon icon={faClock} />
            <p>{date}</p>
          </div>
        </div>
        <img src={image} alt="imagen1" className="blogmore-section-body__image-adapt" />
        {parrafo.map((parrafo) => <p>{parrafo.value}</p>)}
        {id === '4' ? <Legislacion /> : null}
        <hr />
      </div>
    </section>
  );
}
