// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import './ComboBox.css';

export default function ComboBox() {
  return (
    <div>
      <select className="dropdown">
        <option value="factura">FACTURA ELECTRONICA</option>
        <option value="boleta">BOLETA DE VENTA ELECTRONICA</option>
        <option value="nota-cred">NOTA DE CREDITO ELECTRONICA</option>
        <option value="nota-deb">NOTA DE DEBITO ELECTRONICA</option>
        <option value="nota-deb">GUIA DE REMISION ELECTRONICA</option>
      </select>
    </div>
  );
}
